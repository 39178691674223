import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroIcon from '@components/atoms/hero-icon';
import ButtonComponent from '@components/atoms/button/button';
import { LinksContent } from '@components/utils/helper';

import style from './style.module.css';

const EnergyMigrationSection = () => {
  const isSmallScreen = useScreenSize();
  const scheduleDemoLink = LinksContent[1];
  const energyMigrationContainer = twMerge(
    isSmallScreen ? 'px-4 py-10 flex-col' : 'flex-row p-20',
    style.EnergyMigrationContainer,
  );
  const energyHeaderTitle = twMerge(
    isSmallScreen ? 'text-heading-medium' : 'text-heading-xxLarge',
    style.EnergyHeaderTitle,
  );
  const energyCardsContainer = twMerge(
    isSmallScreen ? 'w-full mt-2' : 'w-[26.875rem] items-center',
    style.EnergyCardsContainer,
  );
  const energyCardTitle = twMerge(isSmallScreen ? 'text-heading-small' : 'text-heading-medium', style.EnergyCardTitle);
  const energyCardDescription = twMerge(
    isSmallScreen ? 'text-caption-medium' : 'text-paragraph-small',
    style.EnergyCardDescription,
  );

  return (
    <section className="w-full bg-gradient-custom-10">
      <div className={energyMigrationContainer}>
        <div className={isSmallScreen ? 'w-full' : 'max-w-[38.25rem]'}>
          <h2 className={energyHeaderTitle}>
            Quem pode migrar para o <span className='text-brand-primary-60'>Mercado Livre de Energia?</span>
          </h2>
          <p className={style.EnergyHeaderDescription}>
          Até 2023, apenas grandes consumidores podiam acessar o Mercado Livre de Energia. A partir de janeiro de 2024, todas as empresas que estão em alta e média tensão (Grupo A) já podem usar a energia do fornecedor que quiserem! <br></br> Há duas classificações de consumidores no Mercado Livre de Energia:
          </p>
          {!isSmallScreen && (
            <ButtonComponent
              icon="ArrowRightIcon"
              iconPosition="right"
              iconType="solid"
              iconStyles="w-10 h-10 bg-brand-light-20 text-brand-primary-70"
              kind="primaryCustom"
              size="1"
              labelSize="ml-2"
              label="Agende uma demonstração"
              onClick={() => window.open(scheduleDemoLink?.src,scheduleDemoLink?.typeOfWindow)}
              className={twMerge(style.EnergyCardButton, 'mt-8')}
            />
          )}
        </div>
        <div className={energyCardsContainer}>
          <div className={style.EnergyCards}>
            <div className={style.EnergyCardIcon}>
              <div className="h-10 w-10">
                <HeroIcon type="solid" icon="LightBulbIcon" />
              </div>
            </div>
            <div>
              <h3 className={energyCardTitle}>Consumidores livres</h3>
              <p className={energyCardDescription}>
                Clientes que atualmente têm mais de 500 kW de demanda contratada (em média acima de R$ 50 mil de conta
                mensal) e podem comprar energia de qualquer fornecedor, produzida a partir de qualquer tipo de fonte.
              </p>
            </div>
          </div>
          <div className={style.EnergyCards}>
            <div className={style.EnergyCardIcon}>
              <div className="h-10 w-10">
                <HeroIcon type="solid" icon="BoltIcon" />
              </div>
            </div>
            <div>
              <h3 className={energyCardTitle}>Consumidores especiais</h3>
              <p className={energyCardDescription}>
                Clientes que atualmente têm entre 30kW e 500 kW de demanda contratada (entre R$10 mil e R$ 50 mil de
                conta mensal) e só podem comprar energia de fornecedores que geram a partir de fontes alternativas (como
                solar, eólica e biomassa).
              </p>
            </div>
          </div>
          {isSmallScreen && (
            <ButtonComponent
              icon="ArrowRightIcon"
              iconPosition="right"
              iconType="solid"
              iconStyles="w-10 h-10 bg-brand-light-20 text-brand-primary-70"
              kind="primaryCustom"
              size="1"
              label="Agende uma demonstração"
              onClick={() => window.open(scheduleDemoLink?.src,scheduleDemoLink?.typeOfWindow)}
              className={twMerge('flex justify-between mt-3 w-full', style.EnergyCardButton)}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default EnergyMigrationSection;
