import { IconType } from '@components/atoms/hero-icon';

import iconCCEECard from '@assets/icon-ccee2.svg';
import iconHandShake from '@assets/icon-handshake.svg';

export type IconName = 'DocumentTextIcon' | 'DocumentCheckIcon' | 'BoltIcon' | 'MagnifyingGlassCircleIcon';
export interface ICardData {
  id: string;
  icon: IconName | string;
  isImage?: boolean;
  title: string;
  content: string;
  iconType?: IconType;
}

export const cardData: ICardData[] = [
  {
    id: '1',
    icon: 'DocumentTextIcon',
    isImage: false,
    title: '01',
    iconType: 'solid',
    content: 'Avaliamos sua conta de luz e seu contrato vigente.',
  },
  {
    id: '2',
    icon: 'DocumentCheckIcon',
    isImage: false,
    title: '02',
    iconType: 'solid',
    content: 'Realizamos estudo de viabilidade e sugerimos o produto ideal.',
  },
  {
    id: '3',
    icon: iconHandShake,
    isImage: true,
    title: '03',
    iconType: 'solid',
    content: 'Contrato assinado! A Clarke se torna a gestora de energia da sua empresa.',
  },
  {
    id: '4',
    icon: 'BoltIcon',
    isImage: false,
    title: '04',
    iconType: 'solid',
    content: 'A Clarke ajuda sua empresa a comprar energia.',
  },
  {
    id: '5',
    icon: iconCCEECard,
    isImage: true,
    title: '05',
    iconType: 'solid',
    content: 'Gerenciamos a migração para o Mercado Livre de Energia.',
  },
  {
    id: '6',
    icon: 'MagnifyingGlassCircleIcon',
    isImage: false,
    title: '06',
    iconType: 'solid',
    content: 'Mensalmente, acompanhamos os resultados e buscamos novas oportunidades de economia.',
  },
];
