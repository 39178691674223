import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import * as Tabs from '@radix-ui/react-tabs';

import { useScreenSize } from '@hooks/useScreenSize';
import HeroIcon from '@components/atoms/hero-icon';

import iconAneelCard from '@assets/icon-condution.svg';
import iconCCEECard from '@assets/icon-ccee.svg';

import style from './style.module.css';

function SecureEnvironmentSection() {
  const isSmallScreen = useScreenSize();
  const [activeTab, setActiveTab] = useState('tab1');
  const secureEnvironmentRoot = twMerge(isSmallScreen ? 'px-4 py-12' : 'p-20', style.SecureEnvironmentRoot);
  const secureEnvironmentContent = twMerge(
    isSmallScreen ? 'w-full flex-col gap-0' : 'w-[100rem] flex-row gap-10',
    'flex justify-between ',
  );
  const secureContentContainer = twMerge(
    isSmallScreen ? 'min-h-fit' : 'min-h-[31.25rem] max-w-[50rem]',
    style.ContentContainer,
  );
  const secureHeading = twMerge(isSmallScreen ? 'text-heading-medium' : 'text-heading-xxLarge', 'font-bold');
  const secureParagraph = twMerge(
    isSmallScreen ? 'text-paragraph-medium mt-4' : 'text-paragraph-large mt-5',
    'font-regular',
  );
  const cardContainer = twMerge(
    isSmallScreen ? 'h-[24.6rem] w-[22.875rem] mt-11' : 'w-[26.125rem] h-[23.75rem] mt-[4.56rem]',
    style.CardContainer,
  );
  const cardContainerPrev = twMerge(
    isSmallScreen ? 'h-[24.6rem] w-[22.875rem] mt-11 ml-6' : 'w-[26.125rem] h-[23.75rem] mt-[4.56rem] ml-14',
    style.CardContainerPrev,
  );
  const cardText = twMerge(isSmallScreen ? 'text-paragraph-medium' : 'text-paragraph-large', 'font-normal');
  const tabListBtn = twMerge(isSmallScreen ? 'justify-center mt-6' : 'ml-20 -mt-12', 'flex gap-6');
  const standardBtnCustom = twMerge(isSmallScreen ? 'w-12 h-12' : 'h-14 w-14', style.standardBtnCustom);

  return (
    <section className={style.secureEnvironmentSection}>
      <Tabs.Root defaultValue="tab1" onValueChange={setActiveTab} className={secureEnvironmentRoot}>
        <div className={secureEnvironmentContent}>
          <div>
            <div className={secureContentContainer}>
              <div>
                <h2 id="tab1-heading" className={secureHeading}>
                  É um <span className="text-brand-primary-60">ambiente seguro</span> para comercializadores de energia
                  e consumidores livres
                </h2>
                <p className={secureParagraph}>
                  O Mercado Livre de Energia é regulamentado pela legislação brasileira. Como forma de garantir um funcionamento transparente, justo e seguro - o ACL é fiscalizado por órgãos e instituições do Governo Federal como a Agência Nacional de Energia Elétrica (ANEEL) e a Câmera de Comercialização de Energia Elétrica (CCEE).
                </p>
                <p className={twMerge(secureParagraph, 'mt-4')}>
                  Também existem órgãos responsáveis por fiscalizar essas regras. Os principais são: a Agência Nacional
                  de Energia Elétrica (ANEEL) e a Câmera de Comercialização de Energia Elétrica (CCEE).
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row ">
            <Tabs.Content value="tab1">
              <div>
                <div className={twMerge(cardContainer, 'bg-brand-primary-0')}>
                  <div className={style.CardHeader}>
                    <div className={twMerge(style.CardIconContainer, 'bg-brand-primary-80')}>
                      <div className={style.CardIcon}>
                        <img src={iconAneelCard} alt="Ícone ANEEL"/>
                      </div>
                    </div>
                    <h3 className={twMerge(style.CardTitle, 'text-brand-primary-80')}>Aneel</h3>
                  </div>
                  <div className="w-full mt-6">
                    <p className={twMerge(cardText, 'text-brand-primary-80 ')}>
                      A Aneel é um órgão que tem como principal função regular e fiscalizar o setor de energia elétrica.
                      Desempenha atividades como elaborar normas e regras para o funcionamento do setor da energia no
                      Brasil. Também promove ações de defesa ao direito dos consumidores de energia elétrica, incluindo
                      os consumidores livres.
                    </p>
                  </div>
                </div>
              </div>
            </Tabs.Content>
            <Tabs.Content value="tab2">
              <div className={twMerge(cardContainer, 'bg-brand-primary-80')}>
                <div className={style.CardHeader}>
                  <div className={twMerge(style.CardIconContainer, 'bg-brand-primary-0')}>
                    <div className={style.CardIcon}>
                      <img src={iconCCEECard} alt="Ícone CCEE"/>
                    </div>
                  </div>
                  <h3 className={twMerge(style.CardTitle, 'text-brand-primary-0')}>CCEE</h3>
                </div>
                <div className="w-full mt-6">
                  <p className={twMerge(cardText, 'text-brand-primary-0')}>
                    A CCEE garante a segurança e eficiência do processo de compra e venda de energia no Mercado Livre de
                    Energia. É o órgão responsável por contabilizar os montantes comercializados, gerir contratos e pela
                    operacionalização do mercado.
                  </p>
                </div>
              </div>
            </Tabs.Content>

            <div className={style.PrevCards}>
              {activeTab === 'tab1' && (
                <div className={twMerge(cardContainerPrev, 'bg-brand-primary-80')}>
                  <div className={style.CardHeader}>
                    <div className={twMerge(style.CardIconContainer, 'bg-brand-primary-0')}>
                      <div className={style.CardIcon}>
                        <img src={iconCCEECard} alt="Ícone CCEE"/>
                      </div>
                    </div>
                    <h3 className={twMerge(style.CardTitle, 'text-brand-primary-0')}>CCEE</h3>
                  </div>
                  <div className="w-full mt-6">
                    <p className={twMerge(cardText, 'text-brand-primary-0')}>
                      A CCEE garante a segurança e eficiência do processo de compra e venda de energia no Mercado Livre
                      de Energia. É o órgão responsável por contabilizar os montantes comercializados, gerir contratos e
                      pela operacionalização do mercado.
                    </p>
                  </div>
                </div>
              )}

              {activeTab === 'tab2' && (
                <div className={twMerge(cardContainerPrev, 'bg-brand-primary-0')}>
                  <div className={style.CardHeader}>
                    <div className={twMerge(style.CardIconContainer, 'bg-brand-primary-80')}>
                      <div className={style.CardIcon}>
                        <img src={iconAneelCard} alt="Ícone ANEEL" />
                      </div>
                    </div>
                    <h3 className={twMerge(style.CardTitle, 'text-brand-primary-80')}>Aneel</h3>
                  </div>
                  <div className="w-full mt-6">
                    <p className={twMerge(cardText, 'text-brand-primary-80 ')}>
                      A Aneel é um órgão que tem como principal função regular e fiscalizar o setor de energia elétrica.
                      Desempenha atividades como elaborar normas e regras para o funcionamento do setor da energia no
                      Brasil. Também promove ações de defesa ao direito dos consumidores de energia elétrica, incluindo
                      os consumidores livres.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Tabs.List className={tabListBtn}>
          <Tabs.Trigger
            value="tab1"
            aria-selected={activeTab === 'tab1'}
            onClick={() => setActiveTab('tab1')}
            aria-label="Alterar conteúdo para visualizar o card CCEE"
          >
            <div
              className={twMerge(
                activeTab === 'tab1' ? style.secondaryCustomButtonClasses : style.primaryCustomButtonClasses,
               standardBtnCustom,
              )}
            >
              <HeroIcon type="solid" icon="ArrowLeftIcon" extraClassNames={isSmallScreen ? 'w-8 h-8' : 'w-10 h-10'} />
            </div>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="tab2"
            aria-selected={activeTab === 'tab2'}
            onClick={() => setActiveTab('tab2')}
            aria-label="Alterar conteúdo para visualizar o card ANEEL"
          >
            <div
              className={twMerge(
                activeTab === 'tab2' ? style.secondaryCustomButtonClasses : style.primaryCustomButtonClasses,
                standardBtnCustom,
              )}
            >
              <HeroIcon type="solid" icon="ArrowRightIcon" extraClassNames={isSmallScreen ? 'w-8 h-8' : 'w-10 h-10'} />
            </div>
          </Tabs.Trigger>
        </Tabs.List>
      </Tabs.Root>
    </section>
  );
}

export default SecureEnvironmentSection;
