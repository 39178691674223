import { Helmet } from 'react-helmet';

interface MetaTagsProps {
  title: string;
  description: string;
  robots?: string;
  language?: string;
  charset?: string;
  viewport?: string;
  canonicalLink?: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({
  title,
  description,
  canonicalLink,
  robots = 'index, follow',
  language = 'pt-br',
  charset = 'utf-8',
  viewport = 'width=device-width, initial-scale=1',
}) => (
  <Helmet>
    <meta charSet={charset} />
    <meta name="viewport" content={viewport} />
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="robots" content={robots} />
    <meta httpEquiv="content-language" content={language} />
    <link rel="canonical" href={canonicalLink} />
  </Helmet>
);

export default MetaTags;
