import React from 'react';
import { twMerge } from 'tailwind-merge';

import { useScreenSize } from '@hooks/useScreenSize';
import HeroIcon, { IconType, IconName } from '@components/atoms/hero-icon';

import style from './style.module.css';

interface CardProps {
  id: string;
  icon: IconName;
  iconType?: IconType;
  isImage?: boolean;
  title: string;
  content: string;
  className?: string;
  children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ id, icon, iconType = 'outline', isImage = false, title, content, className, children }) => {
  const isSmallScreen = useScreenSize();
  const cardContent = twMerge(isSmallScreen ? 'w-full' : 'w-[11.25rem]', style.cardContent);
  const card = twMerge(isSmallScreen ? 'min-h-[13rem]' : 'h-[13.75rem]', style.card);

  return (
    <div
      key={id}
      className={`${card} ${className} ${id === '4' ? 'overflow-hidden' : ''}`}
      role="group"
      aria-labelledby={`card-${id}-title`}
    >
      <div className={style.cardIconContainer}>
        <div className={style.cardIconBackground}>
          {isImage ? (
            <img
              src={icon}
              alt={`Ícone ${icon}`}
              className={style.cardIcon}
            />
          ) : (
            <HeroIcon
              type={iconType}
              icon={icon}
              extraClassNames={style.cardIcon}
            />
          )}
        </div>
      </div>
      <h3 id={`card-${id}-title`} className={style.cardTitle}>
        {title}
      </h3>
      <p className={cardContent}>
        {content}
      </p>
      {children}
    </div>
  );
};

export default Card;
