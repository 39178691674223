import logoAlfredo from '@assets/logo01.svg';
import logoBotafogo from '@assets/logo02.svg';
import logoCSN from '@assets/logo03.svg';
import logoMarcel from '@assets/logo04.svg';
import logoBurguerKing from '@assets/logo05.svg';
import logoMobly from '@assets/logo06.svg';
import logoMapleBearCanadianSchool from '@assets/logo07.svg';
import logoMSGStones from '@assets/logo08.svg';
import logoDrConsulta from '@assets/logo09.svg';
import logoFlamengo from '@assets/logo10.svg';
import logoLeCordonBlueu from '@assets/logo11.svg';

export const logosCarousel = [
  [
    { src: logoLeCordonBlueu, alt: 'Logotipo da escola Le Cordon Bleu' },
    { src: logoFlamengo, alt: 'Logotipo do Clube de Regatas do Flamengo' },
    { src: logoDrConsulta, alt: 'Logotipo da empresa Dr. Consulta' },
    { src: logoMSGStones, alt: 'Logotipo da empresa MSG Stones' },
    { src: logoMapleBearCanadianSchool, alt: 'Logotipo da Maple Bear Canadian School' },
    { src: logoMobly, alt: 'Logotipo da empresa Mobly' },
    { src: logoBurguerKing, alt: 'Logotipo da empresa Burger King' },
    { src: logoMarcel, alt: 'Logotipo da empresa Marcel Group' },
    { src: logoCSN, alt: 'Logotipo da Companhia Siderúrgica Nacional' },
    { src: logoBotafogo, alt: 'Logotipo da empresa Botafogo' },
    { src: logoAlfredo, alt: 'Logotipo da empresa Alfredo' },
  ],
];

interface CardEnergyInsightCard {
  title: string;
  description: string;
  icon: string;
}

export const CardEnergyInsightsData: CardEnergyInsightCard[] = [
  {
    title: 'Monitoramento de consumo',
    description: 'Nosso time de especialistas estará sempre conectada com os dados de consumo da sua empresa.',
    icon: 'EyeIcon',
  },
  {
    title: 'Relatório',
    description:
      'Mensalmente, nossos clientes têm acesso a relatórios de economia automáticos para entender seus resultados.',
    icon: 'DocumentChartBarIcon',
  },
  {
    title: 'Recontratação de energia',
    description:
      'Buscando sempre o menor preço, nosso time é proativo para te orientar sobre o momento certo de contratar mais energia.',
    icon: 'LightBulbIcon',
  },
];

interface ServiceCard {
  title: string;
  description: string;
  iconName: string;
  iconType: 'solid' | 'outline';
}

export const firstServicesCardSolutions: ServiceCard[] = [
  {
    title: 'Migração',
    description:
      'Oferecemos representação e assessoria durante todo o processo de migração para o mercado livre, cuidando das rotinas e dos contratos junto à distribuidora, ao fornecedor de energia e aos órgãos competentes.',
    iconName: 'ArrowRightStartOnRectangleIcon',
    iconType: 'solid',
  },
  {
    title: 'Contratação',
    description:
      'Consultoria na definição do melhor contrato de energia e acesso à tecnologia da Clarke para contratar energia com o fornecedor que oferece preço e segurança.',
    iconName: 'DocumentDuplicateIcon',
    iconType: 'outline',
  },
  {
    title: 'Gestão',
    description:
      'Consultoria, representação e auditoria mensal no mercado livre de energia. O cliente tem comodidade com o relacionamento com a distribuidora, com o fornecedor de energia e com os órgãos competentes.',
    iconName: 'UserPlusIcon',
    iconType: 'outline',
  },
];

export const secondServicesCardSolutions: ServiceCard[] = [
  {
    title: 'Tecnologia',
    description:
      'Utilizamos da tecnologia com foco em melhorar a rotina e resultados de nossos clientes. Com a Clarke, você acompanha online o consumo de sua empresa e tem acesso a relatórios que vão facilitar e agilizar a tomada de decisão.',
    iconName: 'CodeBracketSquareIcon',
    iconType: 'outline',
  },
  {
    title: 'Atendimento',
    description:
      'Atendimento consultivo e especializado aliado com uma experiência simples dos processos de mercado livre de energia oferecida por nossa área do cliente.',
    iconName: 'UsersIcon',
    iconType: 'outline',
  },
  {
    title: 'Segurança',
    description:
      'Tenha visibilidade do risco de cada fornecedor e tome decisões seguras ao contratar energia de uma das maiores empresas de energia do Brasil.',
    iconName: 'ShieldCheckIcon',
    iconType: 'outline',
  },
];

interface ContentOfLinks {
  name: string;
  src: string;
  typeOfWindow: string;
}

export const LinksContent: ContentOfLinks[] = [
  {
    name: 'TalkToEnergySpecialist',
    src: 'https://wa.me/15557005576?text=Oi!%20Vim%20atrav%C3%A9s%20do%20site%20da%20Clarke%20Energia%20e%20quero%20falar%20com%20um%20especialista.',
    typeOfWindow: '_blank',
  },
  {
    name: 'ScheduleDemo',
    src: 'https://meetings.hubspot.com/giselle-ramos',
    typeOfWindow: '_blank',
  },
  {
    name: 'WantToKnowMore',
    src: '/governo-abre-mercado-livre-de-energia-para-a-alta-tensao-a-partir-de-2024/',
    typeOfWindow: '_blank',
  },
  {
    name: 'ClarkeVarejo',
    src: 'https://wa.me/15557005576?text=Ol%C3%A1!%20Pode%20me%20explicar%20melhor%20sobre%20Clarke%20Varejo%3F%20A%20velocidade%20e%20facilidade%20oferecidas%20me%20chamaram%20a%20aten%C3%A7%C3%A3o.',
    typeOfWindow: '_blank',
  },
  {
    name: 'ClarkeGestao',
    src: 'https://wa.me/15557005576?text=Ol%C3%A1!%20Pode%20me%20explicar%20melhor%20sobre%20o%20Clarke%20Gest%C3%A3o%3F%20Tenho%20interesse%20em%20uma%20solu%C3%A7%C3%A3o%20personalizada.',
    typeOfWindow: '_blank',
  },
  {
    name: 'InitiativeLiterOfLight',
    src: 'https://blog.clarke.com.br/mais-nova-b-corp-brasileira-clarke-chega-a-marca-de-100-clientes-no-mercado-livre-de-energia/',
    typeOfWindow: '_blank',
  },
  {
    name: 'WhatIsMarketEnergy',
    src: '/mercado-livre-de-energia/',
    typeOfWindow: '_blank',
  },
];

export const LinksFooter: ContentOfLinks[] = [
  {
    name: 'Linkedin',
    src: 'https://www.linkedin.com/company/clarkeenergia/',
    typeOfWindow: '_blank',
  },
  {
    name: 'Facebook',
    src: 'https://www.facebook.com/clarkeenergia',
    typeOfWindow: '_blank',
  },
  {
    name: 'Instagram',
    src: 'https://www.instagram.com/clarkeenergia/',
    typeOfWindow: '_blank',
  },
  {
    name: 'Youtube',
    src: 'https://www.youtube.com/@clarkeenergia',
    typeOfWindow: '_blank',
  },
  {
    name: 'Vacancies',
    src: 'https://clarke.gupy.io/',
    typeOfWindow: '_blank',
  },
  {
    name: 'Address',
    src: 'https://www.google.com/maps/search/?api=1&query=Clarke+Energia',
    typeOfWindow: '_blank',
  },
];
