import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import ButtonComponent from '@components/atoms/button/button';
import { LinksContent } from '@components/utils/helper';

import solution01 from '@assets/solution-01.png';
import solution02 from '@assets/solution-02.png';

import style from './style.module.css';

const OurSolutionsSection = () => {
  const isSmallScreen = useScreenSize();
  const clarkeVarejoLink = LinksContent[3];
  const clarkeGestaoLink = LinksContent[4];
  const ourSolutionsSection = style.OurSolutionsSection;
  const ourContainer = twMerge(isSmallScreen ? 'px-4 py-10' : 'p-20', style.OurContainer);
  const ourTitle = twMerge(isSmallScreen ? 'text-heading-medium' : 'text-display-small', style.OurTitle);
  const containerContent = twMerge(isSmallScreen ? 'flex-col' : 'flex-row', style.ContainerContent);
  const card = twMerge(isSmallScreen ? 'min-h-[41.9375rem]' : 'min-h-[48.125rem]', style.Card);
  const cardText = twMerge(isSmallScreen ? 'my-6 text-paragraph-small' : 'mt-6 text-[0.9375rem]', style.CardText);
  const imageCard = twMerge(isSmallScreen ? 'mt-4' : 'mt-6', 'object-cover z-0');
  const ourButton = twMerge(
    isSmallScreen ? 'min-w-full ' : 'w-[29.3rem] min-w-full text-center',
    'relative z-10',
    style.OurButton,
  );
  const cardTitleFirst = twMerge(isSmallScreen ? 'text-heading-large' : 'text-heading-xLarge', style.CardTitleFirst);
  const cardTitleSecond = twMerge(isSmallScreen ? 'text-heading-large' : 'text-heading-xLarge', style.CardTitleSecond);

  return (
    <section id="nossas-solucoes" className={ourSolutionsSection}>
      <div className={ourContainer}>
        <h2 className={ourTitle}>
          As nossas <span className="text-brand-primary-60">soluções</span>
        </h2>
        <div className={containerContent}>
          <div className={twMerge(card, 'bg-[#EEF7E9] relative')}>
            <div>
              <h3 className={cardTitleFirst}>
                Clarke<span className="font-bold">Varejo</span>
              </h3>
              <p className={twMerge(cardText, 'text-brand-primary-70')}>
                Para clientes que buscam comodidade, o <span className="font-bold">Clarke Varejo</span> oferece uma
                solução acessível e mais rápida.<br></br>
                Com um custo até <span className="font-bold">20% menor</span> que a média do mercado, a Clarke Varejo
                garante a segurança de uma das maiores empresas de energia, assegurando{' '}
                <span className="font-bold">confiabilidade</span> e <span className="font-bold">eficiência</span>.
              </p>
            </div>
            <img
              src={solution01}
              alt="Smartphone mostrando uma prévia do sistema da Clarke"
              width={523}
              height={424}
              className={twMerge(imageCard, !isSmallScreen && 'mt-[18.625rem] w-fit h-[21.5rem] absolute')}
              loading="lazy"
              decoding="async"
            />
            <ButtonComponent
              icon="ArrowRightIcon"
              iconPosition="right"
              iconType="solid"
              iconStyles="bg-brand-light-20 text-brand-primary-70"
              kind="primaryCustom"
              size="1"
              labelSize="w-full"
              label="Quero velocidade e facilidade"
              onClick={() => window.open(clarkeVarejoLink.src, clarkeVarejoLink.typeOfWindow)}
              className={ourButton}
            />
          </div>

          <div className={twMerge(card, 'bg-[#f0f0f7] relative')}>
            <div>
              <h3 className={cardTitleSecond}>
                Clarke<span className="font-bold">Gestão</span>
              </h3>
              <p className={twMerge(cardText, 'text-brand-secondary-40')}>
                Com o <span className="font-bold">Clarke Gestão</span>, sua empresa terá uma gestora independente focada
                em contratar energia com as melhores condições possíveis, gerando uma concorrência entre mais de 70
                fornecedores. <br></br> Este produto é indicado para clientes que desejam contratos personalizados e
                acompanhamento dedicado, com suporte completo da tecnologia Clarke, reconhecida como a mais avançada do
                mercado.
              </p>
            </div>
            <img
              src={solution02}
              alt="Smartphone mostrando uma prévia do sistema da Clarke"
              width={523}
              height={424}
              className={twMerge(imageCard, !isSmallScreen && 'mt-[18.625rem] w-fit h-[26.5rem] absolute')}
              loading="lazy"
              decoding="async"
            />
            <ButtonComponent
              icon="ArrowRightIcon"
              iconPosition="right"
              iconType="solid"
              iconStyles="bg-brand-light-20 text-brand-secondary-30 z-10"
              kind="quaternary"
              size="1"
              labelSize="w-full"
              label="Quero uma solução personalizada"
              onClick={() => window.open(clarkeGestaoLink.src, clarkeGestaoLink.typeOfWindow)}
              className={ourButton}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSolutionsSection;