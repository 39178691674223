import React from 'react';

import AccordionItem from '@components/atoms/accordion-item/accordion-item';

interface AccordionProps {
  items: Array<{ 
    "@type": string;
    "name": string;
    "acceptedAnswer": {
      "@type": string;
      "text": string;
    }
  }>;
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <AccordionItem 
          key={`accordion-item-${index}`}
          title={item.name} 
          content={item.acceptedAnswer.text} 
        />
      ))}
    </div>
  );
};

export default Accordion;
