import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroSection from '@components/molecules/hero-section';
import ChallengeSection from '@components/molecules/challenge-section';
import EnergyMigrationSection from '@components/molecules/energy-migration-section';
import IndustryBannerSection from '@components/molecules/industry-section';
import FreeFutureSection from '@components/molecules/free-future-section';
import AllSectorsSection from '@components/molecules/all-sectors-section';
import WhatChangesSection from '@components/molecules/what-changes-section';
import SecureEnvironmentSection from '@components/molecules/secure-environment-section';
import HowWorksSection from '@components/molecules/how-works-section';
import MetaTags from '@components/atoms/meta-tags';

import heroImage4 from '@assets/hero-section-4.png';

import style from '@pages/free-energy-marketplace-page/style.module.css';

function FreeEnergyMarketplacePage() {
  const isSmallScreen = useScreenSize();
  const heroBannerTitle = twMerge(
    isSmallScreen ? 'text-heading-xxLarge w-[20rem] pt-8 px-4' : 'w-[26.5rem] text-display-small leading-[3rem]',
    style.HeroBannerTitle,
  );
  const heroBannerParagraph = twMerge(
    isSmallScreen ? 'mt-2 text-paragraph-medium px-4' : 'mt-2 text-heading-xSmall w-[27.25rem]',
    style.HeroBannerParagraph,
  );
  const bannerStyle = twMerge(isSmallScreen ? 'hidden' : 'block', style.BannerStyle);

  const heroBannerSection = twMerge(
    isSmallScreen ? style.HeroBannerSectionMobile : style.HeroBannerSectionDesktop,
    'mx-auto',
  );
  const heroBannerContainer = twMerge(
    isSmallScreen && 'items-start justify-center h-full space-y-4',
    style.HeroBannerContainer,
  );

  return (
    <section className="relative overflow-x-hidden" aria-label="Página Mercado Livre de Energia">
      <MetaTags
        title="Mercado Livre de Energia - o que é e como funciona"
        description="Conheça o Mercado Livre de Energia, ambiente comercial no qual empresas podem comprar energia de qualquer fornecedor."
        canonicalLink="https://clarke.com.br/mercado-livre-de-energia"
      />
      <HeroSection
        imgPath={heroImage4}
        imgAlt="Foto de uma pessoa segurando uma lampada"
        imgHeight={770}
        imgWidth={780}
        imgExtraClasses={bannerStyle}
        aria-hidden="true"
      >
        <div className={heroBannerSection}>
          {isSmallScreen && (
            <img
              src={heroImage4}
              alt="Foto de uma pessoa segurando uma lampada"
              className={style.HeroMobileImage}
            />
          )}
          <div className={heroBannerContainer}>
            <h1 className={heroBannerTitle}>Descubra o Mercado Livre de Energia</h1>
            <p className={heroBannerParagraph}>Liberdade e economia na conta de luz.</p>
          </div>
        </div>
      </HeroSection>
      <ChallengeSection />
      <HowWorksSection />
      <SecureEnvironmentSection />
      <EnergyMigrationSection />
      <AllSectorsSection />
      <WhatChangesSection />
      <IndustryBannerSection />
      <FreeFutureSection />
    </section>
  );
}

export default FreeEnergyMarketplacePage;
