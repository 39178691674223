import { IconName, IconType } from '@components/atoms/hero-icon';

export interface ICard {
  id: string;
  icon: IconName;
  title: string;
  content: string;
  iconType: IconType;
}

export const cardsHowWorks: ICard[] = [
  {
    id: '1',
    icon: 'ArrowPathRoundedSquareIcon',
    iconType: 'outline',
    title: 'Flexibilidade',
    content:
      'Quem participa do Mercado Livre de Energia (MLE) ou Ambiente de Contratação Livre (ACL) pode negociar todas as condições comerciais das contratações, como fornecedor, quantidade, preço, período de suprimento e forma de pagamento.',
  },
  {
    id: '2',
    icon: 'PlusCircleIcon',
    iconType: 'outline',
    title: 'Alternativa ao ACR',
    content:
      'Este formato é uma alternativa ao Ambiente de Contratação Regulada (ACR), também chamado de Mercado Regulado ou Mercado Cativo, que concentra todos os consumidores residenciais e também a maioria das empresas do país. Nele, a energia é comprada das distribuidoras.',
  },
  {
    id: '3',
    icon: 'CurrencyDollarIcon',
    iconType: 'outline',
    title: 'Redução de custos',
    content:
      'No ACL, existe uma grande flexibilidade na estrutura de preços e contratos, que proporcionam redução nos custos de energia. Como existe concorrência e isenção das bandeiras tarifárias, os preços tendem a ser até 40% mais baixos. E os contratos são personalizados de acordo com a necessidade de cada indústria e empresa.',
  },
];

export const cardsInvolvedAgents: ICard[] = [
  {
    id: '4',
    icon: 'LightBulbIcon',
    iconType: 'outline',
    title: 'Consumidores livres',
    content:
      'Empresas do grupo A de consumo de energia, que realizaram a migração para o Mercado Livre de Energia, e agora têm redução nos custos com a conta de luz, podendo contratar energia proveniente de qualquer fonte de geração.',
  },
  {
    id: '5',
    icon: 'BoltIcon',
    iconType: 'outline',
    title: 'Fornecedores de energia',
    content:
      'Empresas que vendem eletricidade, são responsáveis pela entrega e distribuição de energia ao consumidor final. Eles competem entre si para conquistar cada vez mais consumidores.',
  },
  {
    id: '6',
    icon: 'AdjustmentsHorizontalIcon',
    iconType: 'outline',
    title: 'Comercializadoras e gestoras',
    content:
      'Empresas responsáveis pela intermediação entre os consumidores e fornecedores de energia elétrica. Os fornecedores também podem atuar como comercializadores de energia.',
  },
];

export const cardsBenefits: ICard[] = [
  {
    id: '7',
    icon: 'BanknotesIcon',
    iconType: 'outline',
    title: 'Isenção das bandeiras tarifárias',
    content:
      'Os fornecedores competem pela energia do consumidor, o que pode gerar uma redução de custos com energia de até 40%. Além disso, os consumidores livres encontram flexibilidade com contratos bilaterais personalizados de acordo com suas necessidades e ficam isentos das bandeiras tarifárias.',
  },
  {
    id: '8',
    icon: 'ShieldCheckIcon',
    iconType: 'outline',
    title: 'Previsibilidade e redução de riscos',
    content:
      'Proporciona a previsibilidade orçamentária e redução de riscos. Além da possibilidade de maior planejamento com os custos de energia, o que traz eficiência para a gestão financeira e maior segurança nas operações.',
  },
  {
    id: '9',
    icon: 'GlobeAmericasIcon',
    iconType: 'outline',
    title: 'Sustentabilidade',
    content:
      'Aproxima as empresas e consumidores da sustentabilidade, pois consumidores livres têm a possibilidade de optar por fontes renováveis, diversificando a matriz energética e reduzindo a dependência de fontes não renováveis.',
  },
];
