import React from 'react';
import { useScreenSize } from '@hooks/useScreenSize';
import { Outlet } from 'react-router-dom';

import Navbar from '@components/molecules/navbar-desktop/';
import { NavbarMobile } from '@components/molecules/navbar-mobile/';
import Footer from '@components/molecules/footer/';

const Layout: React.FC = () => {
  const isSmallScreen = useScreenSize();

  return (
    <>
      {isSmallScreen ? <NavbarMobile /> : <Navbar />}
      <Outlet />
      <Footer idPage="rodape" />
    </>
  );
};

export default Layout;
