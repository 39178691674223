import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import bannerImage from '@assets/banner-industry.png';

import style from './style.module.css';

const IndustryBannerSection = () => {
  const isSmallScreen = useScreenSize();
  const bannerContainer = twMerge(isSmallScreen ? 'px-4 pb-10' : 'px-20 pb-20', style.BannerContainer);
  const bannerTitle = twMerge(
    isSmallScreen ? 'text-heading-medium  py-8' : 'text-heading-xxLarge pb-3',
    style.BannerTitle,
  );
  const bannerDescription = twMerge(
    isSmallScreen ? 'text-paragraph-medium w-full' : 'text-paragraph-large w-[60rem]',
    style.BannerDescription,
  );
  const imgIndustry = twMerge(isSmallScreen ? 'w-[23.875rem]' : 'w-full px-40 ', 'block');
  const sectionIndustry = twMerge(
    isSmallScreen ? 'bg-gradient-custom-70-half' : 'bg-gradient-custom-70-half-2',
    'w-full',
  );

  return (
    <section className={sectionIndustry}>
      <div className={bannerContainer}>
        <div className={style.BannerContent}>
          <img
            src={bannerImage}
            alt="Ilustração de uma usina sustentavel"
            aria-hidden="true"
            className={imgIndustry}
            loading="lazy"
            decoding="async"
          />
          <h2 className={bannerTitle}>
            A <span className="text-brand-primary-0">indústria</span> é responsável por{' '}
            <span className="text-brand-primary-0">41% de todo o consumo de energia</span>
          </h2>
          <p className={bannerDescription}>
            Os benefícios do Mercado Livre de Energia para as indústrias são evidentes! O setor é o maior consumidor de
            energia no Brasil. Com a migração para o ACL usando energia limpa, indústrias de diversos setores como
            plástico, rochas e metalurgia, mostram que o seu desenvolvimento anda lado a lado com a sustentabilidade.
            Realizar investimentos em eficiência energética são cruciais para amenizar os impactos ambientais do setor e
            também para estimular a competitividade das empresas no mercado.
          </p>
        </div>
      </div>
    </section>
  );
};

export default IndustryBannerSection;
