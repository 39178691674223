import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { mainContent, PathMap } from './helper';

import style from './style.module.css';

export default function SkipToContent() {
  const location = useLocation();
  const currentPath = location.pathname as keyof PathMap;

  const skipLink = mainContent[currentPath] || '#';

  useEffect(() => {
    const hash = location.hash;
    const validHashes = ['#depoimentos', '#simulador-de-energia', '#nossas-solucoes', '#como-funciona'];
  
    if (validHashes.includes(hash)) {
      setTimeout(() => {
        const pageElement = document.querySelector(hash);
        if (pageElement) {
          const elementPosition = pageElement.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: elementPosition - 100,
            behavior: 'smooth',
          });
        } else {
          console.warn('Elemento não encontrado:', hash);
        }
      }, 0); 
    }
  }, [location]);

  return (
    <>
      <a href={skipLink} className={style.skipToContent}>
        Ir para conteúdo principal
      </a>
      <a href="#rodape" className={style.skipToContent}>
        Ir para rodapé
      </a>
    </>
  );
}
