import React, { useEffect, useState } from 'react';

interface FormProps {
  formStyle?: string;
}

const loadHubspotForm = (setFormLoaded: React.Dispatch<React.SetStateAction<boolean>>) => {
  const script = document.createElement('script');
  script.setAttribute('charset', 'utf-8');
  script.type = 'text/javascript';
  script.src = 'https://js.hsforms.net/forms/embed/v2.js';

  script.onload = () => {
    if ((window as any).hbspt) {
      (window as any).hbspt.forms.create({
        region: 'na1',
        portalId: '21312607',
        formId: '260bbeee-88b9-4d38-b187-c80d9a068159',
        target: '#hubspot-form-container',
      });
      setFormLoaded(true);
    }
  };

  script.onerror = () => {
    console.error('Failed to load HubSpot form script.');
    setFormLoaded(false);
  };

  document.body.appendChild(script);

  return () => {
    if (document.body.contains(script)) {
      document.body.removeChild(script);
    }
  };
};


const FormComponent: React.FC<FormProps> = ({ formStyle }) => {
  const [formLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    const cleanup = loadHubspotForm(setFormLoaded);
    return cleanup;
  }, []);

  return (
    <div id="hubspot-form-container" className={formStyle}>
      {!formLoaded && <p>Carregando formulário...</p>}
    </div>
  );
};

export default FormComponent;