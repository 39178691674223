import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroIcon from '@components/atoms/hero-icon';
import Carousel from '@components/molecules/carousel/carousel';
import { logosCarousel } from '@components/utils/helper';

import style from './style.module.css';

const CustomersSection: React.FC = () => {
  const isSmallScreen = useScreenSize();
  const CustomerSection = twMerge(isSmallScreen ? 'py-10' : 'py-20', style.CustomerSection);
  const HeadingTitle = twMerge(
    isSmallScreen ? 'text-heading-xSmall' : 'text-heading-medium',
    'relative font-bold text-center',
  );
  const ClientsLink = twMerge(
    isSmallScreen ? 'mt-0 text-paragraph-small' : 'mt-6 text-paragraph-large',
    style.ClientLink,
  );

  return (
    <section className={CustomerSection} aria-labelledby="customers-section-heading">
      <div className={style.CustomerContainer}>
        <p id="customers-section-heading" className={HeadingTitle}>
          Somos uma gestora de energia aprovada por
          <span className="text-brand-primary-60"> líderes </span>
          do mercado
        </p>
        <Carousel logos={logosCarousel} />
        <a href="/sobre-nos#depoimentos" target='_blank' className={ClientsLink}>
          Veja a opinião dos nossos clientes
          <HeroIcon type="solid" icon="ArrowRightIcon" extraClassNames="ml-2 w-5 h-5 inline" />
        </a>
      </div>
    </section>
  );
};

export default CustomersSection;
