import React from 'react';
import { useScreenSize } from '@hooks/useScreenSize';
import style from './style.module.css';

interface HeroSectionProps {
  children: React.ReactNode;
  imgPath: string;
  imgAlt: string;
  imgExtraClasses?: string;
  imgHeight?: number;
  imgWidth?: number;
  imgContent?: string;
  imgContentClasses?: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  children,
  imgPath,
  imgAlt,
  imgExtraClasses = '',
  imgWidth,
  imgHeight,
  imgContent,
  imgContentClasses = '',
}) => {
  const isSmallScreen = useScreenSize();

  return (
    <section className={style.HeroSection} aria-label="Hero Section">
      <div className={style.HeroSectionMainTextWrapper}>
        {children}
        {!isSmallScreen && (
          <div className="relative w-full overflow-y-clip flex justify-center items-center">
            <img
              src={imgPath}
              alt={imgAlt}
              width={imgWidth}
              height={imgHeight}
              className={imgExtraClasses}
              aria-label={imgAlt}
            />
            {imgContent && (
              <img
                src={imgContent}
                alt={imgAlt}
                className={imgContentClasses}
                aria-hidden="true"
              />
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default HeroSection;
