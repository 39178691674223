import React, { useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import TabContent from '@components/organisms/tab-content/tab-content';
import TabTrigger from '@components/molecules/tab-trigger/tab-trigger';

import { cardData } from './helper';

import style from './style.module.css';

const TabsContainer: React.FC = () => {
  const isSmallScreen = useScreenSize();
  const [activeTab, setActiveTab] = useState('tab1');

  const tab1Cards = cardData.slice(0, 5);
  const tab2Cards = cardData.slice(3);
  const mobileTabs = cardData.slice(0, 6);

  const tabsRoot = twMerge(isSmallScreen ? 'py-10' : 'py-20', style.TabsRoot);
  const tabsContainerClasses = twMerge(
    isSmallScreen ? 'flex-col px-4 w-full' : `gap-10 items-center ${activeTab === 'tab1' ? 'pl-[30.8rem]' : 'pl-1'}`,
    'flex',
  );
  const tabsTitle = twMerge(
    isSmallScreen ? 'w-full text-heading-xLarge' : 'w-[23.125rem] text-heading-xxLarge',
    'font-bold mb-9',
  );

  return (
    <Tabs.Root className={tabsRoot} value={activeTab} onValueChange={setActiveTab} aria-label="Products and Solutions">
      <div className={tabsContainerClasses}>
        <div className="flex flex-col">
          <h2 className={tabsTitle}>
            As etapas para que seu negócio tenha a<span className="text-brand-primary-70"> melhor economia</span>
          </h2>
          {!isSmallScreen && (<Tabs.List className={style.tabsList} aria-label="Navegar entre os cards">
              <TabTrigger tabValue="tab1" activeTab={activeTab} setActiveTab={setActiveTab} />
              <TabTrigger tabValue="tab2" activeTab={activeTab} setActiveTab={setActiveTab} />
          </Tabs.List>)}
        </div>
        <div className="flex flex-col gap-6 w-full ">
          {!isSmallScreen ? (
            <>
              <TabContent tabId="tab1" cards={tab1Cards} />
              <TabContent tabId="tab2" cards={tab2Cards} />
            </>
          ) : (
            <TabContent tabId={activeTab} cards={mobileTabs} />
          )}
        </div>
      </div>
    </Tabs.Root>
  );
};

export default TabsContainer;
