import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import Carousel from '@components/molecules/carousel/carousel';
import { logosCarousel } from '@components/utils/helper';

import style from './style.module.css';

const AllSectorsSection = () => {
  const isSmallScreen = useScreenSize();
  const allSectorsSection = twMerge(isSmallScreen ? 'py-10' : 'py-20', style.allSectorsSection);
  const allSectorsContent = twMerge(isSmallScreen ? 'flex-col px-4' : 'flex-row', style.allSectorsContent);
  const allSectorsHeader = twMerge(
    isSmallScreen ? 'w-full text-heading-medium' : 'w-[34.88rem] text-heading-xxLarge',
    'flex font-bold',
  );
  const allSectorsDescription = twMerge(isSmallScreen ? 'w-full' : 'w-[34.875rem]', 'flex');

  return (
    <section className={allSectorsSection} aria-labelledby="sectors-section-heading">
      <div className={style.allSectorsContainer}>
        <div className={allSectorsContent}>
          <div className={allSectorsHeader}>
            <h2 id="sectors-section-heading">
              <span className="text-brand-primary-60">Para todos os setores: </span> Saiba quem já economiza
            </h2>
          </div>

          <div className={allSectorsDescription}>
            <p className={isSmallScreen ? 'text-paragraph-medium' : 'text-paragraph-large'}>
              Empreendimentos dos mais diferentes setores podem migrar para o Mercado Livre de Energia. O Botafogo (SP),
              a Mobly e a Le Cordon Bleu são exemplos de empresas que realizaram a migração para o Mercado Livre de
              Energia e agora podem economizar até 40% na conta de luz usando somente{' '}
              <a
                href="https://clarke.com.br/energia-limpa/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                energia limpa.
              </a>
              .
            </p>
          </div>
        </div>
        <Carousel logos={logosCarousel} extraAtributes={{ loading: "lazy", decoding: "async" }} />
      </div>
    </section>
  );
};

export default AllSectorsSection;
