import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';
import * as Tabs from '@radix-ui/react-tabs';

import Card from '@components/atoms/card/card';
import { cardsHowWorks, cardsInvolvedAgents, cardsBenefits, ICard } from './helper';

import style from './style.module.css';

function HowWorksSection() {
  const isSmallScreen = useScreenSize();
  const [activeTab, setActiveTab] = useState('tab1');
  const howWorksContainerClasses = twMerge(isSmallScreen ? 'px-4 py-12' : 'p-20', style.howWorksContainerClasses);
  const tabListClasses = twMerge(isSmallScreen ? 'gap-2 mb-4' : 'gap-6 mb-6', style.tabListClasses);
  const tabHeadingClasses = twMerge(
    isSmallScreen ? 'text-heading-medium leading-[2rem]' : 'text-heading-xLarge leading-[3rem]',
    style.tabHeadingClasses,
  );
  const cardContainerClasses = twMerge(isSmallScreen ? 'flex-col mt-8' : 'flex-row mt-16', style.cardContainerClasses);
  const tabButtonClasses = twMerge(
    isSmallScreen ? 'text-paragraph-medium h-[3.125rem] py-[' : 'w-[14rem] h-[3.875rem] text-heading-xSmall',
    style.standardBtnCustom,
  );

  return (
    <section id="como-funciona" className={style.howWorksSectionClasses}>
      <div className={howWorksContainerClasses}>
        <Tabs.Root defaultValue="tab1" onValueChange={(value) => setActiveTab(value)} className="w-full">
          <Tabs.List aria-label="tabs example" className={tabListClasses}>
            <Tabs.Trigger value="tab1" asChild>
              <div
                className={twMerge(
                  activeTab === 'tab1' ? style.primaryCustomButtonClasses : style.iconPrimaryStyleButtonClasses,
                  tabButtonClasses,
                )}
              >
                Como funciona
              </div>
            </Tabs.Trigger>
            <Tabs.Trigger value="tab2" asChild>
              <div
                className={twMerge(
                  activeTab === 'tab2' ? style.primaryCustomButtonClasses : style.iconPrimaryStyleButtonClasses,
                  tabButtonClasses,
                )}
              >
                Quem faz parte
              </div>
            </Tabs.Trigger>
            <Tabs.Trigger value="tab3" asChild>
              <div
                className={twMerge(
                  activeTab === 'tab3' ? style.primaryCustomButtonClasses : style.iconPrimaryStyleButtonClasses,
                  tabButtonClasses,
                )}
              >
                Os benefícios
              </div>
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="tab1" className={style.tabContentClasses}>
            <h2 className={tabHeadingClasses}>
              Como funciona o <span className="text-brand-primary-60">Mercado Livre de Energia?</span>
            </h2>
            <div className={cardContainerClasses}>
              {cardsHowWorks.map((card: ICard) => (
                <Card key={card.id} {...card} />
              ))}
            </div>
          </Tabs.Content>
          <Tabs.Content value="tab2" className={style.tabContentClasses}>
            <h2 className={tabHeadingClasses}>
              Os<span className="text-brand-primary-60"> principais agentes </span>do Mercado Livre de Energia são:
            </h2>
            <div className={cardContainerClasses}>
              {cardsInvolvedAgents.map((card: ICard) => (
                <Card key={card.id} {...card} />
              ))}
            </div>
          </Tabs.Content>
          <Tabs.Content value="tab3" className={style.tabContentClasses}>
            <h2 className={tabHeadingClasses}>
              Quais são <span className="text-brand-primary-60"> os benefícios </span>do Mercado Livre de Energia?
            </h2>
            <div className={cardContainerClasses}>
              {cardsBenefits.map((card: ICard) => (
                <Card key={card.id} {...card} />
              ))}
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </section>
  );
}

export default HowWorksSection;
