import React from 'react';
import * as OutlineIcons from '@heroicons/react/24/outline';
import * as SolidIcons from '@heroicons/react/24/solid';

export type IconName = keyof typeof OutlineIcons | keyof typeof SolidIcons;
export type IconType = 'outline' | 'solid';

export const iconNames: IconName[] = [...Object.keys(OutlineIcons), ...Object.keys(SolidIcons)] as IconName[];

export interface IconProps extends React.ComponentProps<'svg'> {
  icon: IconName;
  extraClassNames?: string;
  ariaLabel?: string;
  type?: IconType;
}

const HeroIcon: React.FC<IconProps> = ({ icon, extraClassNames, ariaLabel, type, ...attrs }) => {
  let IconComponent = type === 'solid' ? SolidIcons[icon] : OutlineIcons[icon];

  /* eslint-disable no-console */
  if (type === 'solid' && SolidIcons[icon]) {
    IconComponent = SolidIcons[icon];
  } else if (type === 'outline' && OutlineIcons[icon]) {
    IconComponent = OutlineIcons[icon];
  } else {
    console.error(`Ícone "${icon}" não encontrado.`);
    return null;
  }
  /* eslint-enable no-console */

  return <IconComponent className={extraClassNames} aria-label={ariaLabel} {...attrs} />;
};

export default HeroIcon;
