import { useEffect, useState } from 'react';
import { useModalContext } from '@contexts/modalContext';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import HeroIcon from '@components/atoms/hero-icon';
import ButtonComponent from '@components/atoms/button/button';
import SkipToContent from '@components/atoms/skip-content/skipToContent';
import ModalComponent from '@components/atoms/modal/modal';
import FormComponent from '@components/atoms/form';

import {
  HOME_PATH,
  ABOUT_US_PATH,
  OUR_SOLUTIONS_PATH,
  FREE_ENERGY_MARKETPLACE_PATH,
  BLOG_PATH,
  CLIENT_AREA_PATH,
} from '@routers/constants';

import logoClarkeEnergiaDoubleLine from '@assets/logo-clarke-energia.svg';

import style from './style.module.css';

export function NavbarMobile() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const { isModalOpen, setModalOpen } = useModalContext();
  const location = useLocation();

  const handleToggleClick = () => setIsNavOpen((prevState) => !prevState);

  const handleNavLinkClick = (path: string) => {
    if (location.pathname === path) {
      setIsNavOpen(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setIsNavOpen(false);
      setShouldScroll(true);
    }
    return;
  };

  useEffect(() => {
    if (shouldScroll) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setShouldScroll(false);
    }
  }, [location.pathname, shouldScroll]);

  const openModal = () => {
    setIsNavOpen(false);
    setTimeout(() => {
      if (!isModalOpen) {
        setModalOpen(true);
      }
    }, 700);
  };

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isModalOpen]);

  useEffect(() => {
    const resizeNav = () => {
      const navFullscreen = document.getElementById('NavbarMobileFullscreen');
      const navOverlay = document.getElementById('NavbarMobileOverlay');

      if (navFullscreen && navOverlay) {
        const radius = Math.sqrt(window.innerHeight ** 2 + window.innerWidth ** 2);
        const diameter = radius * 2;
        navOverlay.style.width = `${diameter}px`;
        navOverlay.style.height = `${diameter}px`;
        navOverlay.style.marginTop = `-${radius}px`;
        navOverlay.style.marginLeft = `-${radius}px`;
      }
    };

    window.addEventListener('resize', resizeNav);
    resizeNav();

    return () => window.removeEventListener('resize', resizeNav);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isNavOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isNavOpen]);

  return (
    <nav className={style.NavbarMobileContainer} role="navigation">
      <SkipToContent />
      <Link
        to="/"
        aria-current={location.pathname === HOME_PATH ? 'page' : undefined}
        onClick={() => handleNavLinkClick(HOME_PATH)}
      >
        <img src={logoClarkeEnergiaDoubleLine} alt="Logotipo da Clarke Energia" width={116} height={64} />
      </Link>
      <div id="NavMobileContainer" className={style.NavMobileContainer}>
        <div
          id="NavbarMobileOverlay"
          className={twMerge(style.NavbarMobileOverlay, isNavOpen ? style.open : style.opacity0)}
        />
        <div
          id="NavbarMobileFullscreen"
          className={twMerge(style.NavbarMobileFullscreen, isNavOpen ? style.open : style.translateXFull)}
        >
          <div className={style.NavbarMobileLogo}>
            <Link
              to="/"
              aria-current={location.pathname === HOME_PATH ? 'page' : undefined}
              onClick={() => handleNavLinkClick(HOME_PATH)}
            >
              <img src={logoClarkeEnergiaDoubleLine} alt="Logotipo da Clarke Energia" width={116} height={64} />
            </Link>
          </div>
          <ul>
          <li>
              <Link
                to={HOME_PATH}
                className={twMerge('block', location.pathname === HOME_PATH ? 'font-bold' : undefined)}
                aria-current={location.pathname === HOME_PATH ? 'page' : undefined}
                onClick={() => handleNavLinkClick(HOME_PATH)}
              >
                Inicio
              </Link>
            </li>
            <li>
              <Link
                to={FREE_ENERGY_MARKETPLACE_PATH}
                className={twMerge('block', location.pathname === FREE_ENERGY_MARKETPLACE_PATH ? 'font-bold' : undefined)}
                aria-current={location.pathname === FREE_ENERGY_MARKETPLACE_PATH ? 'page' : undefined}
                onClick={() => handleNavLinkClick(FREE_ENERGY_MARKETPLACE_PATH)}
              >
                Mercado livre de energia
              </Link>
            </li>
            <li>
              <Link
                to={OUR_SOLUTIONS_PATH}
                className={twMerge('block', location.pathname === OUR_SOLUTIONS_PATH ? 'font-bold' : undefined)}
                aria-current={location.pathname === OUR_SOLUTIONS_PATH ? 'page' : undefined}
                onClick={() => handleNavLinkClick(OUR_SOLUTIONS_PATH)}
              >
                Nossas soluções
              </Link>
            </li>
            <li>
              <Link
                to={ABOUT_US_PATH}
                className={twMerge('block', location.pathname === ABOUT_US_PATH ? 'font-bold' : undefined)}
                aria-current={location.pathname === ABOUT_US_PATH ? 'page' : undefined}
                onClick={() => handleNavLinkClick(ABOUT_US_PATH)}
              >
                Sobre nós
              </Link>
            </li>
            <li>
              <Link to={BLOG_PATH} className="block">
                Blog
              </Link>
            </li>
          </ul>
          <div className={style.NavbarButtonMobile}>
            <ButtonComponent
              kind="secondary"
              size="2"
              label="Simulador de economia"
              className={style.NavbarButtonMobileContent}
              onClick={openModal}
            />
            <ButtonComponent
              kind="primary"
              size="2"
              label="Área do cliente"
              onClick={() => window.open(CLIENT_AREA_PATH, '_blank')}
              className={style.NavbarButtonMobileContent}
            />
          </div>
        </div>

        <button
          id="NavbarMobileToggle"
          onClick={handleToggleClick}
          aria-label="Toggle navigation menu"
          aria-expanded={isNavOpen}
          aria-controls="NavbarMobileFullscreen"
          className={style.NavbarMobileToggle}
        >
          {isNavOpen ? <HeroIcon type="solid" icon="XMarkIcon" /> : <HeroIcon type="solid" icon="Bars3Icon" />}
        </button>
      </div>
      <ModalComponent
        title="Calcule seu potencial de economia"
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        className="text-center"
      >
        <FormComponent />
      </ModalComponent>
    </nav>
  );
}
