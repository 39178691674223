import React from 'react';

import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import style from './carousel.module.css';

interface Logo {
  src: string;
  alt: string;
}

interface CarouselProps {
  logos: Logo[][];
  extraAtributes?: React.ImgHTMLAttributes<HTMLImageElement>;
}

const Carousel: React.FC<CarouselProps> = ({ logos, extraAtributes }) => {
  const isSmallScreen = useScreenSize();
  const logoImage = twMerge(isSmallScreen ? 'h-[3rem]' : 'max-w-[11.7rem] h-[4.0625rem]', 'block');
  const logoList = twMerge(isSmallScreen ? 'w-[200.25rem]' : 'w-[318.75rem]', style.LogoList);  
  const lastLogoImageListItem = twMerge(isSmallScreen ? 'mr-10' : 'mr-[6.25rem]', style.LastLogoImageListItem);

  return (
    <article className={style.LogoSliderContainer}>
      <div className={logoList} role="list">
        {logos.concat(logos).map((logoGroup, index) => (
          <div className={style.LogoItem} key={`logoGroup-${index}`} role="listitem">
            <ul className={style.LogoImageList}>
              {logoGroup.map((logo, logoIndex) => (
                <li
                  key={`logo-${logoIndex}`}
                  className={twMerge(
                    style.LogoImageListItem,
                    logoIndex === logoGroup.length - 1 ?  lastLogoImageListItem : '',
                  )}
                >
                  <img src={logo.src} alt={logo.alt} className={logoImage} loading='lazy' {...extraAtributes}/>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </article>
  );
};

export default Carousel;
