import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';
import { useModalContext } from '@contexts/modalContext';

import HeroIcon from '@components/atoms/hero-icon';
import ButtonComponent from '@components/atoms/button/button';

import style from './style.module.css';

const RetailSection = () => {
  const { isModalOpen, setModalOpen } = useModalContext();
  const isSmallScreen = useScreenSize();
  const retailSection = twMerge(isSmallScreen ? 'px-4 py-8' : 'p-20', style.RetailSection);
  const retailContainer = twMerge(isSmallScreen ? 'px-0' : 'px-20', style.RetailContainer);
  const retailTitle = twMerge(isSmallScreen ? 'text-heading-medium mt-[2.125rem]' : 'text-display-small', style.RetailTitle);
  const retailDescription = twMerge(isSmallScreen ? 'text-paragraph-medium' : 'text-heading-xSmall', style.RetailDescription);
  const cardTitle = twMerge(isSmallScreen ? 'text-paragraph-medium' : 'text-paragraph-large', style.CardTitle);
  const card = twMerge(isSmallScreen ? 'p-4 min-h-[6.5rem]' : 'p-[1.375rem] min-h-[8.6875rem]', style.Card);
  const retailCardsGroup = twMerge(isSmallScreen ? 'mt-6 gap-4' : 'mt-16 gap-10', style.RetailCardsGroup);
  const ctaButton = twMerge(isSmallScreen ? 'w-full h-12 flex justify-between w-full h-12 mt-6 mb-[2.125rem] text-paragraph-medium' : 'h-14 text-paragraph-large gap-3', style.ctaButton);

  const openModal = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };

  return (
    <section className={retailSection}>
      <div className={retailContainer}>
        <div className={style.RetailHeader}>
          <div className='flex justify-between items-center'>
            <h2 className={retailTitle}>
              Conheça o <span className="text-brand-primary-0"> Clarke Varejo</span>
            </h2>
            {!isSmallScreen && (
            <ButtonComponent
              icon="ArrowRightIcon"
              iconPosition="right"
              iconType="solid"
              iconSize="w-[1.275rem]"
              iconStyles={twMerge(isSmallScreen ? 'w-8 h-8' : 'w-10 h-10', "bg-brand-light-20 text-brand-primary-70")}
              kind="primaryCustom"
              size="1"
              label="Simular agora"
              className={ctaButton}
              onClick={openModal}
            />
          )}
          </div>
          <h3 className={retailDescription}>
              A solução da Clarke Energia para empresas que desejam contratar energia limpa e barata, de forma
              simplificada e sem riscos.
          </h3>
        
        </div>
        <div className={retailCardsGroup}>
          <div className={card}>
            <div className={style.IconBlock}>
              <div className="h-6 w-6">
                <HeroIcon type="solid" icon="ClockIcon" />
              </div>
            </div>
            <p className={cardTitle}>6 meses de cobertura em caso de atraso de migração</p>
          </div>
          <div className={card}>
            <div className={style.IconBlock}>
             <div className="h-6 w-6">
                <HeroIcon type="solid" icon="LightBulbIcon" />
              </div>
            </div>
            <p className={cardTitle}>Flexibilidade de +/- 100% no consumo de energia</p>
          </div>
          <div className={card}>
            <div className={style.IconBlock}>
             <div className="h-6 w-6">
                <HeroIcon type="solid" icon="LockOpenIcon" />
              </div>
            </div>
            <p className={cardTitle}>Sem necessidade de apresentar garantias</p>
          </div>
          <div className={card}>
            <div className={style.IconBlock}>
             <div className="h-6 w-6">
                <HeroIcon type="solid" icon="ShieldCheckIcon" />
              </div>
            </div>
            <p className={cardTitle}>Cobertura total dos Encargos Setoriais</p>
          </div>
          <div className={card}>
            <div className={style.IconBlock}>
             <div className="h-6 w-6">
                <HeroIcon type="solid" icon="BuildingOffice2Icon" />
              </div>
            </div>
            <p className={cardTitle}>Segurança de um {isSmallScreen && <br></br>}fornecedor de energia com +100 anos de atuação</p>
          </div>
          <div className={card}>
            <div className={style.IconBlock}>
             <div className="h-6 w-6">
                <HeroIcon type="solid" icon="RocketLaunchIcon" />
              </div>
            </div>
            <p className={cardTitle}>Atendimento de uma startup premiada</p>
          </div>
        </div>
        {isSmallScreen && (
          <ButtonComponent
            icon="ArrowRightIcon"
            iconPosition="right"
            iconType="solid"
            iconSize="w-[1.275rem]"
            iconStyles={twMerge(isSmallScreen ? 'w-8 h-8' : 'w-10 h-10', "bg-brand-light-20 text-brand-primary-70")}
            kind="primaryCustom"
            size="1"
            label="Simular agora"
            className={ctaButton}
            onClick={openModal}
          />
        )}
      </div>
    </section>
  );
};

export default RetailSection;

