import { useState, useEffect } from 'react';

export const useScreenSize = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    let resizeTimeout: number | null = null;

    const checkScreenSize = () => {
      if (resizeTimeout !== null) {
        cancelAnimationFrame(resizeTimeout);
      }
      resizeTimeout = requestAnimationFrame(() => {
        setIsSmallScreen(window.innerWidth <= 1190);
      });
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
      if (resizeTimeout !== null) {
        cancelAnimationFrame(resizeTimeout);
      }
    };
  }, []);

  return isSmallScreen;
};
