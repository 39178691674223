import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Layout from '@components/layout/main-layout';
import HomePage from '@pages/home-page';
import AboutUsPage from '@pages/about-us-page';
import OurSolutionsPage from '@pages/our-solutions-page';
import FreeEnergyMarketplacePage from '@pages/free-energy-marketplace-page';

import { HOME_PATH, ABOUT_US_PATH, OUR_SOLUTIONS_PATH, FREE_ENERGY_MARKETPLACE_PATH, BLOG_PATH } from './constants';

const InstitutionalSiteRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={HOME_PATH} element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path={ABOUT_US_PATH} element={<AboutUsPage />} />
        <Route path={OUR_SOLUTIONS_PATH} element={<OurSolutionsPage />} />
        <Route path={FREE_ENERGY_MARKETPLACE_PATH} element={<FreeEnergyMarketplacePage />} />
        <Route path={BLOG_PATH} element={<div>Blog</div>} />

        <Route path="*" element={<Navigate to={HOME_PATH} replace />} />
      </Route>
    </Routes>
  );
};

export default InstitutionalSiteRouter;
