import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import ClarkeServiceCard, { ClarkeServiceCardProps } from '@components/molecules/clarke-service-card';

import style from './style.module.css';

interface ClarkeServicesSectionProps {
  title: string;
  highlightTitleColor?: string;
  highlightCardTitleColor: string;
  bgColor: string;
  cards: ClarkeServiceCardProps[];
}

const ClarkeServicesSection: React.FC<ClarkeServicesSectionProps> = ({
  title,
  highlightCardTitleColor,
  bgColor,
  cards,
}) => {
  const isSmallScreen = useScreenSize();
  const clarkeServicesContainer = twMerge(isSmallScreen ? 'py-12 px-4' : 'py-16 px-20', style.ClarkeServicesContainer);
  const clarkeServicesTitle = twMerge(
    isSmallScreen ? 'text-heading-medium' : 'text-display-small',
    style.ClarkeServicesTitle,
    style.highlightTitleColor,
  );
  const clarkeCards = twMerge(isSmallScreen ? 'flex-col' : 'flex-row', style.ClarkeCards);

  return (
    <section className={twMerge(bgColor, 'w-full text-brand-gray-80')}>
      <div className={clarkeServicesContainer}>
        <h2 className={clarkeServicesTitle}>
          {title}
          <span className="font-light text-brand-gray-40">fazemos</span>
        </h2>
        <div className={clarkeCards}>
          {cards.map((card, index) => (
            <ClarkeServiceCard
              key={index}
              title={card.title}
              description={card.description}
              iconName={card.iconName}
              iconType={card.iconType}
              highlightCardTitleColor={highlightCardTitleColor}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClarkeServicesSection;
