import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroIcon from '@components/atoms/hero-icon';
import { LinksContent } from '@components/utils/helper';

import literOfLight from '@assets/liter-of-light.png';

import style from './style.module.css';

const LiterOfLightSection = () => {
  const isSmallScreen = useScreenSize();
  const listerOfLightLink = LinksContent[5];
  const lightDescription = twMerge(
    isSmallScreen ? 'text-paragraph-small' : 'text-paragraph-medium',
    style.LightDescription,
  );
  const lightTitle = twMerge(
    isSmallScreen ? 'mb-6 text-heading-medium' : 'mb-8 text-heading-xxLarge',
    style.LightTitle,
  );
  const literOfLightContainer = twMerge(
    isSmallScreen ? 'flex-col px-4 py-10' : 'flex-row px-20',
    style.LiterOfLightContainer,
  );

  const literOfLightContent = twMerge(isSmallScreen ? 'w-full' : 'max-w-[34.875rem]', style.LiterOfLightContent);

  return (
    <section className="w-full text-brand-gray-80" aria-labelledby="liter-of-light-heading">
      <div className={literOfLightContainer}>
        <div className={literOfLightContent}>
          <div>
            <h2 id="liter-of-light-heading" className={lightTitle}>
              A Clarke acredita que <span className="text-brand-primary-60">energia</span> elétrica é um{' '}
              <span className="text-brand-primary-60">direito de todos</span>
            </h2>
            <p className={lightDescription}>
              Uma das missões da Clarke é empoderar o consumidor de energia, mas isso só pode ser plenamente atingido em
              um cenário no qual a eletricidade esteja ao alcance de todos. E é para tornar isso possível que nos
              tornamos apoiadores do <span className="font-bold text-brand-primary-60">Litro de Luz Brasil</span>.
            </p>
            <p className={twMerge(lightDescription, 'mt-4')}>
              Desde 2014, a ONG ajudou mais de <span className="text-brand-primary-60">20 mil brasileiros</span>{' '}
              espalhados por todas as regiões do país. No total, eles já instalaram mais de{' '}
              <span className="text-brand-primary-60">3.700 soluções</span> sustentáveis de eletricidade e iluminação,
              incluindo postes, lampiões, placas solares, baterias e tecnologias desenvolvidas com materiais como
              garrafas PET e canos PVC.
            </p>
            <a href={listerOfLightLink?.src || ''} target={listerOfLightLink?.typeOfWindow || '_blank'} className={style.LightLink}>
              Saiba mais sobre essa iniciativa{' '}
              <HeroIcon type="solid" icon="ArrowRightIcon" extraClassNames="ml-2 w-5 h-5 inline" />
            </a>
          </div>
        </div>
        <div>
          <img
            src={literOfLight}
            alt="Colagem de fotos de pessoas interagindo com a eletrecidade através de garrafas PET e canos PVC"
            width="1118"
            height="657"
            role="img"
            aria-hidden="true"
            className="object-cover block"
            loading="lazy"
            decoding="async"
          />
        </div>
      </div>
    </section>
  );
};

export default LiterOfLightSection;
