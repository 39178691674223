import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import { testimonialsData } from './helper';

import triangle from '@assets/triangle_2.svg';

import style from './style.module.css';

const TestimonialsSection: React.FC = () => {
  const location = useLocation();
  const isSmallScreen = useScreenSize();

  useEffect(() => {
    if (location.hash === '#depoimentos') {
      const depoimentosElement = document.querySelector('#depoimentos');
      if (depoimentosElement) {
        window.scrollTo({
          top: depoimentosElement.getBoundingClientRect().top + window.scrollY + 100, 
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  const testimonialsSection = twMerge(isSmallScreen ? 'px-4 py-10' : 'py-20', style.TestimonialsSection);
  const testimonialsContainer = twMerge(isSmallScreen ? 'px-0' : 'px-20', style.TestimonialsContainer);
  const testimonialsHeading = twMerge(
    isSmallScreen ? 'text-heading-medium ' : 'text-heading-xxLarge',
    style.TestimonialsHeading,
  );
  const testimonialsWrapper = twMerge(
    isSmallScreen ? 'flex-col gap-6' : 'flex-row gap-[1.8125rem]',
    style.TestimonialsWrapper,
  );
  const testimonialContent = twMerge(isSmallScreen ? 'min-h-40' : 'min-h-56', style.TestimonialContent);
  const testimonialCard = twMerge(isSmallScreen ? 'w-full' : 'w-[16.125rem] min-h-fit', style.TestimonialCard);

  return (
    <section id="depoimentos" className={testimonialsSection} aria-labelledby="testimonials-heading">
      <div className={testimonialsContainer}>
        <h2 id="testimonials-heading" className={testimonialsHeading}>
          O que dizem os <span className={style.TestimonialsHeadingHighlight}>nossos clientes</span>
        </h2>
        <div className={testimonialsWrapper}>
          {testimonialsData.map((testimonial, index) => (
            <div key={`testimonial-${index}`} className={testimonialCard} role="article">
              <div className={testimonialContent}>
                <p className={style.TestimonialText}>{testimonial.reviewBody}</p>
                <div className={style.TestimonialTriangle} role="presentation">
                  <img src={triangle} alt="Triângulo invertido" aria-hidden="true" loading="lazy" decoding="async" />
                </div>
              </div>
              <div className={style.TestimonialFooter}>
                <img
                  src={testimonial.reviewer.image.url}
                  alt={`Imagem de ${testimonial.reviewer.name}`}
                  width="92"
                  height="92"
                  className={style.TestimonialImage}
                  loading="lazy"
                  decoding="async"
                />
                <div className={style.TestimonialDetails}>
                  <p className={style.TestimonialName}>{testimonial.reviewer.name}</p>
                  <p className={style.TestimonialPosition}>{testimonial.reviewer.jobTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
