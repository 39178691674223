import React from 'react';
import { twMerge } from 'tailwind-merge';
import * as Tabs from '@radix-ui/react-tabs';

import HeroIcon from '@components/atoms/hero-icon';

import style from './style.module.css';

interface TabTriggerProps {
  tabValue: string;
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const TabTrigger: React.FC<TabTriggerProps> = ({ tabValue, activeTab, setActiveTab }) => (
  <Tabs.Trigger
    value={tabValue}
    role="tab"
    aria-controls={tabValue}
    aria-selected={activeTab === tabValue}
    id={`${tabValue}-tab`}
    onClick={() => setActiveTab(tabValue)}
  >
    <div
      className={twMerge(
        activeTab === tabValue ? style.secondaryCustomButtonClasses : style.primaryCustomButtonClasses,
        style.standardBtnCustom,
      )}
      aria-label={`Alterar conteúdo para visualizar o restante dos cards do lado ${tabValue === 'tab1' ? 'esquerdo' : 'direito'}}`}
    >
      <HeroIcon
        type="solid"
        icon={tabValue === 'tab1' ? 'ArrowLeftIcon' : 'ArrowRightIcon'}
        extraClassNames="w-6 h-6"
      />
    </div>
  </Tabs.Trigger>
);

export default TabTrigger;
