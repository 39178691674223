import testimonial01 from '@assets/testimonial-01.svg';
import testimonial02 from '@assets/testimonial-02.svg';
import testimonial03 from '@assets/testimonial-03.svg';
import testimonial04 from '@assets/testimonial-04.svg';

export const testimonialsData = [
  {
    '@type': 'Review',
    reviewBody: (
      <span className="mx-auto text-center text-[0.9375rem] font-normal leading-normal text-brand-gray-70 py-4">
        “Super atenciosos, trouxeram <span className="font-semibold">soluções distintas, transparência e clareza</span>{' '}
        nas opções e não medindo esforços para colocá-las em prática.”
      </span>
    ),
    reviewer: {
      '@type': 'Person',
      name: 'Maria Mendes',
      jobTitle: 'Gerente de CSC do dr.consulta',
      image: {
        '@type': 'ImageObject',
        url: testimonial01,
        width: 100, 
        height: 100,
      }
    },
  },
  {
    '@type': 'Review',
    reviewBody: (
      <span className="mx-auto text-center text-[0.9375rem] font-normal leading-normal text-brand-gray-70 py-4">
        “A Clarke veio contribuir para a Baumer, naquilo que ela tem know how,{' '}
        <span className="font-semibold">
          facilitando e dando suporte para que a empresa migrasse de forma positiva e com sucesso,
        </span>{' '}
        para o mercado livre de energia.”
      </span>
    ),
    reviewer: {
      '@type': 'Person',
      name: 'Tiago Puggina',
      jobTitle: 'Supervisor administrativo da Baumer',
      image: {
        '@type': 'ImageObject',
        url: testimonial02,
        width: 100, 
        height: 100,
      }
    },
  },
  {
    '@type': 'Review',
    reviewBody: (
      <span className="mx-auto text-center text-[0.9375rem] font-normal leading-normal text-brand-gray-70 py-4">
        “Desde que iniciamos o projeto, foi fornecido{' '}
        <span className="font-semibold">atendimento da mais alta qualidade,</span> muito esclarecedor em cada processo e
        disposto a trazer soluções.”
      </span>
    ),
    reviewer: {
      '@type': 'Person',
      name: 'Kelly Scolaro',
      jobTitle: 'Especialista financeira da rede Burger King',
      image: {
        '@type': 'ImageObject',
        url: testimonial03,
        width: 100, 
        height: 100,
      }
    },
  },
  {
    '@type': 'Review',
    reviewBody: (
      <span className="mx-auto text-center text-[0.9375rem] font-normal leading-normal text-brand-gray-70 py-4">
        “Estamos muito satisfeitos. Essa <span className="font-semibold">redução de custos</span> nos torna mais
        competitivos, em termos de flexibilidade na definição de preços dos nossos produtos e cursos.”
      </span>
    ),
    reviewer: {
      '@type': 'Person',
      name: 'Carla Rosas',
      jobTitle: 'Diretora da Le Cordon Bleu Rio',
      image: {
        '@type': 'ImageObject',
        url: testimonial04,
        width: 100, 
        height: 100,
      }
    },
  },
];
