import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroIcon, { IconName, IconType } from '@components/atoms/hero-icon';

import style from './style.module.css';

export interface CardInfo {
  id: string;
  icon: IconName;
  iconType: IconType;
  title: string;
  content: string;
}

const Card: React.FC<CardInfo> = ({ icon, title, content, id, iconType }) => {
  const isSmallScreen = useScreenSize();
  const cardClasses = twMerge(
    isSmallScreen ? 'w-full min-h-[16rem] gap-4' : 'w-full h-[28rem]',
    style.cardClasses,
  );
  const iconClasses = twMerge(isSmallScreen ? 'w-8 h-8' : 'w-12 h-12', style.iconClasses);
  const titleClasses = twMerge(isSmallScreen ? 'text-heading-medium mt-4' : 'text-heading-large mt-8', style.titleClasses);
  const contentClasses = isSmallScreen ? 'text-paragraph-small mt-3' : 'text-paragraph-medium mt-6';

  return (
    <div key={`cards-${id}`} className={cardClasses}>
      <div className={style.iconWrapperClasses}>
        <div className={iconClasses}>
          <HeroIcon type={iconType} icon={icon} extraClassNames="text-brand-primary-60" />
        </div>
      </div>
      <div className="flex-col">
        <h3 className={titleClasses}>{title}</h3>
        <p className={contentClasses}>{content}</p>
      </div>
    </div>
  );
};

export default Card;
