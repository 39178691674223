import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';
import { Helmet } from 'react-helmet';

import Accordion from '@components/molecules/accordion';
import { faqData } from './helper.tsx';

import style from '@components/molecules/faq-section/style.module.css';

const FAQSection = () => {
  const isSmallScreen = useScreenSize();
  const faqSectionClasses = twMerge(isSmallScreen ? 'px-4 py-10' : 'p-20', style.FAQSection);
  const faqContainerClasses = twMerge(isSmallScreen ? 'px-0' : 'px-20', style.FAQContainer);
  const faqTitleClasses = twMerge(isSmallScreen ? 'mb-2 text-heading-medium' : 'mb-4 text-heading-xxLarge', style.FAQTitle);
  const faqDescriptionClasses = twMerge(
    isSmallScreen ? 'text-paragraph-small' : 'text-paragraph-large w-[37.25rem]',
    style.FAQDescription,
  );

  return (
    <section className={faqSectionClasses}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(faqData)}
        </script>
      </Helmet>
      <div className={faqContainerClasses}>
        <h2 className={faqTitleClasses}>Perguntas Frequentes</h2>
        <p className={faqDescriptionClasses}>
        Explore abaixo as respostas para as perguntas mais frequentes sobre a Clarke Energia. Caso tenha dúvidas adicionais, nossa equipe está à disposição para auxiliá-lo.
        </p>
        <Accordion items={faqData.mainEntity} />
      </div>
    </section>
  );
};

export default FAQSection;
