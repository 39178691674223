import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { twMerge } from 'tailwind-merge';

import { useScreenSize } from '@hooks/useScreenSize';
import Card from '@components/molecules/card/card';
import { ICardData, IconName } from '@components/organisms/tabs-container/helper';

import style from './style.module.css';

interface TabContentProps {
  tabId: string;
  cards: ICardData[];
}

const TabContent: React.FC<TabContentProps> = ({ tabId, cards }) => {
  const isSmallScreen = useScreenSize();
  const cardSlider = twMerge(isSmallScreen ? 'flex-col w-full' : 'flex-row gap-4', style.cardSlider);

  return (
    <Tabs.Content value={tabId} id={tabId} role="tabpanel" aria-labelledby={`${tabId}-tab`}>
      <div className={cardSlider}>
        {cards.map((card, index) => {
          const isLastCard = tabId === 'tab1' && index === cards.length - 1;
          const isSecondLastCard = tabId === 'tab1' && index === cards.length - 2;

          let opacityClass = '';
          if (isLastCard) {
            opacityClass = 'opacity-30';
          } else if (isSecondLastCard) {
            opacityClass = 'opacity-60';
          }

          return (
            <Card
              key={card.id}
              id={card.id}
              icon={card.icon as IconName}
              iconType={card.iconType}
              isImage={card.isImage}
              title={card.title}
              content={card.content}
              className={isSmallScreen ? undefined : opacityClass}
            ></Card>
          );
        })}
      </div>
    </Tabs.Content>
  );
};

export default TabContent;
