import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import marketPlaceFlow from '@assets/marketplace-flow.png';

import style from './style.module.css';

const WhatChangesSection = () => {
  const isSmallScreen = useScreenSize();
  const whatTitle = twMerge(isSmallScreen ? 'text-heading-medium' : 'text-heading-xxLarge', style.WhatTitle);
  const whatChangesContainer = twMerge(isSmallScreen ? 'px-4 py-10' : 'p-20', style.WhatChangesContainer);

  return (
    <section
      className="w-full bg-brand-light-30 "
      role="region"
      aria-labelledby="what-changes-section-heading "
      aria-describedby="what-changes-section-description"
    >
      <div className={whatChangesContainer}>
        <div className={style.WhatChangesContent}>
          <h2 id="what-changes-section-heading" className={whatTitle}>
            Comece a economizar o quanto antes! Saiba como acontece a
            <span className="text-brand-primary-60"> migração para o Mercado Livre de energia:</span>
          </h2>
          <p id="what-changes-section-description" className={style.WhatDescription}>
            Para realizar uma migração segura e assertiva para o Mercado Livre de Energia no Brasil, é fundamental
            contratar uma gestora de energia independente. Com uma expertise especializada, ela poderá ajudar a sua
            empresa e indústria a obter os melhores preços e oferecer uma gestão mais eficiente, estratégica e
            sustentável da energia elétrica.
          </p>
        </div>

        <div>
          <img
            src={marketPlaceFlow}
            alt="Fluxo de migração para o mercado de energia"
            aria-describedby="long-description"
            className="w-full h-auto mx-auto"
            loading="lazy"
            decoding="async"
          />
          <div id="long-description" className="sr-only">
            Fluxo de processos de migração para o mercado de energia, geração, transmissão, distribuição e consumo.
            Clarke está ao centro, cuidando da transmissão e distribuição.
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatChangesSection;
