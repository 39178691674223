import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import style from './style.module.css';

interface InnovationItemProps {
  imgPath: string;
  imgWidth: number;
  imgHeight: number;
  imgAlt: string;
  title: string;
  subtitle: string;
  description: string;
  extraClassName?: string;
}

const InnovationItem: React.FC<InnovationItemProps> = ({
  imgPath,
  imgWidth,
  imgHeight,
  imgAlt,
  title,
  subtitle,
  description,
  extraClassName,
}) => {
  const isSmallScreen = useScreenSize();
  const titleYear = twMerge(!isSmallScreen && 'text-heading-small', 'font-bold leading-normal text-brand-primary-0');
  const descriptionClass = twMerge(isSmallScreen ? 'text-pagragraph-medium' : 'text-paragraph-large', style.Description);
  const innovationContainer = twMerge(
    isSmallScreen ? 'max-w-full gap-4' : 'max-w-[31.25rem] gap-10',
    style.InnovationContainer,
  );

  return (
    <div className={isSmallScreen ? 'w-full' : 'w-1/2'}>
      <div className={innovationContainer}>
        <img
          src={imgPath}
          alt={imgAlt}
          width={imgWidth}
          height={imgHeight}
          className={`${extraClassName} bottom-0 top-0 m-auto object-cover`}
          loading="lazy" decoding="async"
        />
        <div className="w-full">
          <h3 className={titleYear}>{title}</h3>
          <h4 className={style.InnovationSubTitle}>{subtitle}</h4>
          <p className={descriptionClass}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default InnovationItem;
