import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import InnovationItem from '@components/molecules/innovation-item';
import { innovationItems } from './helper';

import style from './style.module.css';

const InnovativeCompanySection = () => {
  const isSmallScreen = useScreenSize();
  const CardsContainer = twMerge(isSmallScreen ? 'flex-col gap-6' : 'flex-row gap-y-20', 'flex w-full flex-wrap ');
  const InnovationCompanySection = twMerge(isSmallScreen ? 'py-10' : 'py-20', style.InnovationCompanySection);
  const InnovationContainer = twMerge(
    isSmallScreen ? 'flex-col px-4' : 'flex-row px-20 items-center',
    style.InnovationContainer,
  );
  const InnovationTitle = twMerge(
    isSmallScreen ? 'mb-8 text-heading-medium' : 'mb-16 max-w-[31.625rem] text-heading-xxLarge',
    'font-bold',
  );

  return (
    <section className={InnovationCompanySection}>
      <div className={InnovationContainer}>
        <h2 className={InnovationTitle}>
          Somos reconhecidos como uma <span className="text-brand-primary-60">empresa inovadora</span>
        </h2>
        <div className={CardsContainer}>
          {innovationItems.map((item, index) => (
            <InnovationItem
              key={`innovation-item-${index}`}
              imgPath={item.imgPath}
              imgAlt={item.imgAlt}
              imgWidth={item.imgWidth}
              imgHeight={item.imgHeight}
              title={item.title}
              subtitle={item.subtitle}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default InnovativeCompanySection;
