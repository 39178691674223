import React from 'react';
import { twMerge } from 'tailwind-merge';
import * as Dialog from '@radix-ui/react-dialog';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroIcon, { IconName, IconType } from '@components/atoms/hero-icon';
import ButtonComponent, { ButtonComponentProps } from '@components/atoms/button/button';

import style from './style.module.css';

interface ModalComponentProps {
  title: string;
  isOpen: boolean;
  setOpen: (key: boolean) => void;
  className?: string;
  icon?: IconName;
  iconType?: IconType;
  modalIcon?: boolean;
  description?: string;
  buttonsProps?: ButtonComponentProps[];
  children?: React.ReactNode;
  labelIcon?: string;
  labelSize?: string;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  icon,
  iconType,
  labelIcon,
  labelSize,
  modalIcon,
  title,
  description,
  children,
  setOpen,
  isOpen,
  buttonsProps,
  className,
  ...props
}) => {
  const isSmallScreen = useScreenSize();
  const modalTitleClass = modalIcon ? style.DialogIconTitleStatus : style.DialogTitleStatus;
  const dialogContent = twMerge(style.DialogContent, isSmallScreen ? 'min-w-[95vw]' : 'w-[90vw]');

  return (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className={style.DialogOverlay} onClick={() => setOpen(false)} />
        <Dialog.Content
          className={twMerge(dialogContent, className)}
          aria-describedby={description ? 'dialog-description' : undefined}
        >
          <Dialog.Title className={modalTitleClass}>{title}</Dialog.Title>
          {description && (
            <Dialog.Description id="dialog-description" className={twMerge(style.DialogDescription, modalIcon && 'text-center')}>
              {description}
            </Dialog.Description>
          )}
          <div className={twMerge(style.DialogContentAction, !children && style.hidden)} {...props}>
            {children}
          </div>
          {buttonsProps && (
            <div className={style.ButtonsContainer}>
              {buttonsProps.length > 0 &&
                buttonsProps.map((btnProps) => (
                  <ButtonComponent
                    className="flex justify-center items-center w-full"
                    key={btnProps.label}
                    {...btnProps}
                  />
                ))}
            </div>
          )}
          <Dialog.Close asChild>
            <button className={style.IconButton} aria-label="Close" onClick={() => setOpen(false)}>
              <HeroIcon
                type="solid"
                icon="XMarkIcon"
                extraClassNames="place-self-center w-6 h-6"
                ariaLabel={labelIcon}
              />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ModalComponent;
