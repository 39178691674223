import innovation1 from '@assets/innovation-01.svg';
import innovation2 from '@assets/innovation-02.svg';
import innovation3 from '@assets/innovation-03.svg';
import innovation4 from '@assets/innovation-04.svg';
import innovation5 from '@assets/innovation-05.svg';
import innovation6 from '@assets/innovation-06.svg';
import innovation7 from '@assets/innovation-07.svg';
import innovation8 from '@assets/innovation-08.svg';

export const innovationItems = [
  {
    imgPath: innovation1,
    imgAlt: "Troféu e texto: Top 100 Open Startups, 2021, 2022, 2023 - Nas categorias EnergyTech e Geral",
    imgWidth: 100,
    imgHeight: 100,
    title: "2021, 2022, 2023",
    subtitle: "Top 100 Open Startups",
    description: "Reconhecimento nas categorias EnergyTech e Geral"
  },
  {
    imgPath: innovation2,
    imgAlt: "Certificação de impacto socioambiental - Empresa B, 2023",
    imgWidth: 100,
    imgHeight: 100,
    title: "2023",
    subtitle: "Empresa B",
    description: "Certificação pelo impacto socioambiental"
  },
  {
    imgPath: innovation3,
    imgAlt: "Logo do selo certificador e texto: Certificação no CCEE 2024",
    imgWidth: 100,
    imgHeight: 100,
    title: "2024",
    subtitle: "CCEE - Profissionais certificados",
    description: "Contamos com profissionais certificados em Mercado de Energia Elétrica"
  },
  {
    imgPath: innovation4,
    imgAlt: "Logo Microsoft e texto: Microsoft for Startups, 2022 - Selecionados para programa de aceleração",
    imgWidth: 100,
    imgHeight: 100,
    title: "2022",
    subtitle: "Microsoft for Startups",
    description: "Selecionados para programa de aceleração da Microsoft"
  },
  {
    imgPath: innovation5,
    imgAlt: "Logo Google e texto: Google For Startups, 2022 - Selecionados para programa de aceleração",
    imgWidth: 100,
    imgHeight: 100,
    title: "2022",
    subtitle: "Google For Startups",
    description: "Selecionados para programa de aceleração do Google"
  },
  {
    imgPath: innovation6,
    imgAlt: "Troféu e texto: Distrito Awards, 2021 - Nas categorias Digital HUB e Disruptiva",
    imgWidth: 100,
    imgHeight: 100,
    title: "2021",
    subtitle: "Distrito Awards",
    description: "Reconhecimento nas categorias Digital HUB e Disruptiva"
  },
  {
    imgPath: innovation7,
    imgAlt: "Logo Canary Venture e texto: Canary Venture, 2020 - Selecionados para receber investimentos",
    imgWidth: 100,
    imgHeight: 100,
    title: "2020",
    subtitle: "Canary Venture",
    description: "Selecionados para receber investimentos da Canary Venture"
  },
  {
    imgPath: innovation8,
    imgAlt: "Logo Forbes e texto: Forbes Under 30, 2021 - Nosso CEO, Pedro Rio, foi selecionado",
    imgWidth: 100,
    imgHeight: 100,
    title: "2021",
    subtitle: "Forbes Under 30",
    description: "Nosso CEO, Pedro Rio, foi reconhecido pela Forbes Under 30"
  }
];
