import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import bannerImage from '@assets/application-preview-banner.png';

import style from './style.module.css';

const BannerSection = () => {
  const isSmallScreen = useScreenSize();
  const bannerContainer = twMerge(isSmallScreen ? 'px-4' : 'px-40 py-20', style.BannerContainer);
  const bannerTitle = twMerge(isSmallScreen ? 'text-heading-medium' : 'text-heading-xLarge', style.BannerTitle);
  const bannerContent = twMerge(isSmallScreen ? 'py-4' : 'pt-8', style.BannerContent);
  const bannerDescription = twMerge(
    isSmallScreen ? 'text-paragraph-medium w-full' : 'text-paragraph-large w-[60rem]',
    style.BannerDescription,
  );

  return (
    <section className={style.BannerSection}>
      <div className={bannerContainer}>
        <img
          src={bannerImage}
          alt="Prévia do sistema da Clarke"
          className="block w-full"
          loading="lazy"
          decoding="async"
        />
        <div className={bannerContent}>
          <h2 className={bannerTitle}>
            Conte com toda a <span className="text-brand-primary-0">tecnologia</span> da Clarke <br></br> no dia a dia
            da sua empresa!
          </h2>
          <h3 className={bannerDescription}>
            Acompanhe 100% a cotação, migração e gestão da energia de forma fácil e personalizada através das
            plataformas fornecidos pela Clarke e garanta a eficiência energética de sua empresa.
          </h3>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
