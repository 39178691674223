import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import iconWorld from '@assets/icon_world.svg';
import iconEmpresaB from '@assets/icon_empresa_b.svg';
import windmill from '@assets/windmill.png';

import style from './style.module.css';

const RenewableEnergySection = () => {
  const isSmallScreen = useScreenSize();

  const RenewableEnergySection = twMerge(isSmallScreen ? 'px-4 py-10' : 'p-20', style.RenewableEnergySection);
  const RenewableEnergyContainer = twMerge(
    isSmallScreen ? 'px-0 flex-col' : 'px-20 flex-row',
    style.RenewableEnergyContainer,
  );
  const RenewableEnergyContainerInside = twMerge(
    isSmallScreen ? 'w-full gap-2' : 'max-w-[31.625rem] gap-8',
    'flex flex-col',
  );
  const RenewableContent = twMerge(isSmallScreen ? 'w-full ml-4' : 'w-[24.625rem] ml-4', '');
  const RenewableEnergyTitle = twMerge(
    isSmallScreen ? 'text-heading-medium' : 'text-heading-xxLarge',
    style.RenewableEnergyTitle,
  );
  const InfoParagraph = twMerge(isSmallScreen ? 'text-paragraph-large' : 'text-heading-small', style.InfoParagraph);

  return (
    <section className={RenewableEnergySection}>
      <div className={RenewableEnergyContainer}>
        <div className={RenewableEnergyContainerInside}>
          <h2 className={RenewableEnergyTitle}>
            Pelo bem do planeta, a Clarke trabalha apenas com
            <span className="text-brand-primary-60"> energia renovável</span>
          </h2>
          <div className={style.InfoBox}>
            <div className={style.InfoIMG}>
              <img src={iconWorld} alt="Icone de um Planeta" width={40} height={40} aria-hidden="true" loading="lazy" decoding="async"/>
            </div>
            <div className={RenewableContent}>
              <p className={InfoParagraph}>
                Já evitamos a emissão de mais de{' '}
                <span className="font-bold text-brand-primary-70">30 mil toneladas de CO2</span>
              </p>
              <p className={style.InfoSmallData}>Dados de 31/01/2024</p>
            </div>
          </div>
          <div className={style.InfoBox}>
            <div className={twMerge(style.InfoIMG, 'h-16 w-16')}>
              <img
                src={iconEmpresaB}
                alt="Icone representando o Selo Empresa B"
                width={23}
                height={40}
                className="mx-auto"
                aria-hidden="true"
                loading="lazy" decoding="async"
              />
            </div>
            <div className={RenewableContent}>
              <p className={InfoParagraph}>
                Somos uma <span className="font-bold text-brand-primary-70">Empresa B certificada</span>, referência em
                impacto socioambiental
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <img
            src={windmill}
            alt="Imagem de um moinho de vento representando energias renováveis"
            width={654}
            height={480}
            aria-hidden="true"
            className="block"
            loading="lazy" decoding="async"
          />
        </div>
      </div>
    </section>
  );
};

export default RenewableEnergySection;
