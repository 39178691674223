import timeline1 from '@assets/timeline-1.png';
import timeline2 from '@assets/timeline-2.png';
import timeline3 from '@assets/timeline-3.png';
import timeline4 from '@assets/timeline-4.png';
import timeline5 from '@assets/timeline-5.png';
import timeline6 from '@assets/timeline-6.png';

export const historyData = [
  {
    '@type': 'Event',
    name: '2019',
    image: {
      '@type': 'ImageObject',
      url: timeline1,
      width: 161,
      height: 166,
      caption: 'Foto dos dois fundadores da Clarke segurando um tablet com a ilustração do Marketplace.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li>
          A Clarke, que tem o Victor e o Pedro como dois de seus fundadores, nasceu com a visão de se tornar um{' '}
          <span className="font-bold">negócio lucrativo enquanto gera impacto positivo no mundo.</span>
        </li>
      </ul>
    ),
  },
  {
    '@type': 'Event',
    name: '2020',
    image: {
      '@type': 'ImageObject',
      url: timeline2,
      width: 161,
      height: 166,
      caption: 'Foto de um dos fundadores da Clarke ao lado de um painel solar gigante.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li>
          A <span className="font-bold">Clarke Energia</span> desenvolveu uma série de soluções até encontrar a melhor
          opção para nossos clientes: <span className="font-bold">o mercado livre de energia.</span>
        </li>
      </ul>
    ),
  },
  {
    '@type': 'Event',
    name: '2021',
    image: {
      '@type': 'ImageObject',
      url: timeline3,
      width: 161,
      height: 166,
      caption: 'Foto de um dos fundadores da Clarke ao lado do integrante do Grupo Nova Aurora.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li>
          Em 2021 a Clarke se tornou o primeiro marketplace de mercado livre de energia do Brasil. A Nova Aurora então
          tornou-se nossa primeira cliente.
        </li>
      </ul>
    ),
  },
  {
    '@type': 'Event',
    name: '2022',
    image: {
      '@type': 'ImageObject',
      url: timeline4,
      width: 161,
      height: 166,
      caption: 'Foto dos membros da Clarke durante o evento de expansão regulatória do setor.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li>
          A startup também apoiou a expansão regulatória do setor com{' '}
          <span className="font-bold">agendas no congresso</span> e com o{' '}
          <span className="font-bold">ministério de minas e energia.</span>
        </li>
      </ul>
    ),
  },
  {
    '@type': 'Event',
    name: '2023',
    image: {
      '@type': 'ImageObject',
      url: timeline5,
      width: 161,
      height: 166,
      caption: 'Foto de duas mulheres segurando a premiação de melhor startup de energia.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li className="list-none -ml-[1.125rem]"> A Clarke Energia se tornou referência.</li>
        <li>
          <span className="font-bold">Top 10 carteiras de clientes</span> do varejo do Brasil.
        </li>
        <li>
          <span className="font-bold">Maior empresa de tecnologia</span> do setor. Selecionada em programas do Google,
          Amazon e Microsoft.
        </li>
        <li>
          <span className="font-bold">3x premiada uma das melhores startups de energia</span> do Brasil pelo prêmio 100
          open startups.
        </li>
      </ul>
    ),
  },
  {
    '@type': 'Event',
    name: '2024',
    image: {
      '@type': 'ImageObject',
      url: timeline6,
      width: 161,
      height: 166,
      caption: 'Foto dos fundadores da Clarke e da Energisa.',
    },
    description: (
      <ul className="ml-[1.125rem] mr-4 list-disc hover:font-light hover:none">
        <li>
          A Energisa se tornou sócia da Clarke Energia. Com isonomia e autonomia, estamos criando a gestora independente
          mais tech do Brasil.
        </li>
      </ul>
    ),
  },
];
