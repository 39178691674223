import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import ButtonComponent from '@components/atoms/button/button';
import HeroIcon, { IconName } from '@components/atoms/hero-icon';
import { CardEnergyInsightsData, LinksContent } from '@components/utils/helper';

import style from './style.module.css';

const EnergyPricesSection = () => {
  const isSmallScreen = useScreenSize();
  const scheduleDemoLink = LinksContent[1];
  const ContainerEnergyPrices = twMerge(
    isSmallScreen ? 'px-4 py-10 flex-col' : 'p-20 flex-row',
    style.ContainerEnergyPrices
  );
  const textContainer = twMerge(
    isSmallScreen ? 'max-w-full' : 'max-w-[29.5rem]',
    style.TextContainer
  );
  const buttonSchedule = twMerge(
    isSmallScreen
      ? 'w-full text-left flex justify-between  mt-8'
      : 'w-fit text-center mt-[18px]',
    style.BtnSchedule
  );
  const headingEnergyPrices = twMerge(
    isSmallScreen ? 'text-heading-medium' : 'text-display-small',
    style.HeadingEnergyPrices
  );
  const subtitleEnergyPrices = twMerge(
    isSmallScreen ? 'text-paragraph-large' : 'text-heading-xSmall',
    style.SubtitleEnergyPrices
  );
  const headingTitle = twMerge(
    isSmallScreen ? 'text-heading-small' : 'text-heading-medium',
    style.HeadingTitle
  );
  const card = twMerge(isSmallScreen ? 'min-h-[8rem]' : 'min-h-[6.375rem] w-[29.5rem]', style.Card);
  const iconStyle = isSmallScreen ? 'h-8 w-8' : 'h-10 w-10';

  return (
    <section className={style.SectionEnergyPrices}>
      <div className={ContainerEnergyPrices}>
        <div className={textContainer}>
          <h2 className={headingEnergyPrices}>
            Ligado no
            <span className="text-brand-primary-0"> preço de energia</span>
          </h2>
          <p className={subtitleEnergyPrices}>
            Nossa tecnologia fará análises constantes para indicar o melhor momento para voltar a contratar energia para
            os anos futuros.
          </p>
          {!isSmallScreen && (
            <ButtonComponent
              icon="ArrowRightIcon"
              iconPosition="right"
              iconType="solid"
              iconStyles='w-10 h-10 bg-brand-light-20 text-brand-primary-70 ml-[0.75rem]'
              kind="primaryCustom"
              size="1"
              label="Agende uma demonstração"
              onClick={() => window.open(scheduleDemoLink?.src,scheduleDemoLink?.typeOfWindow)}
              className={buttonSchedule}
            />
          )}
        </div>
        <div className={style.CardContainerEnergyPrices}>
          {CardEnergyInsightsData.map(({ title, description, icon }) => (
            <div className={card} key={title}>
              <div className={style.IconBlock}>
                <div className={iconStyle}>
                  <HeroIcon type="solid" icon={icon as IconName} />
                </div>
              </div>
              <div>
                <h3 className={headingTitle}>{title}</h3>
                <p className={style.Description}>{description}</p>
              </div>
            </div>
          ))}
        </div>
        {isSmallScreen && (
          <ButtonComponent
            icon="ArrowRightIcon"
            iconPosition="right"
            iconType="solid"
            iconStyles='w-10 h-10 bg-brand-light-20 text-brand-primary-70 ml-[0.75rem]'
            kind="primaryCustom"
            size="1"
            label="Agende uma demonstração"
            onClick={() => window.open(scheduleDemoLink?.src,scheduleDemoLink?.typeOfWindow)}
            className={buttonSchedule}
          />
        )}
      </div>
    </section>
  );
};

export default EnergyPricesSection;