export const faqData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "O que é a Clarke Energia?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A Clarke Energia é uma gestora independente que assessora clientes no Mercado Livre de Energia desde o processo de contação e contratação, passando pela migração até o acompanhamento personalizado com foco na eficiência energética. A Clarke é a maior empresa de tecnologia do setor, reconhecida por práticas de sustentabilidade e ESG."
        }
      },
      {
        "@type": "Question",
        "name": "Quais são os benefícios da Clarke Energia?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A Clarke Energia oferece economia, flexibilidade no consumo de energia, cobertura de encargos setoriais e não exige garantias. Usando da tecnologia em favor/benefício do cliente, proporciona serviço premiado através de atendimento personalizado e  plataformas desenvolvidas internamente com foco em descomplicar e melhorar as soluções no setor elétrico."
        }
      },
      {
        "@type": "Question",
        "name": "Como a tecnologia da Clarke vai ajudar minha empresa?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A Clarke Energia usa da tecnologia para mudar o mercado de energia, entregando um serviço melhor para o seu cliente e um mercado melhor também para fornecedores e stakeholders. Nossas plataformas garantem ao cliente o melhor contrato de energia que ele pode encontrar no momento da sua busca. São elas: "
        }
      },
      {
        "@type": "Question",
        "name": "Eu posso ser cliente da Clarke Energia?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A Clarke é uma gestora independente especialista no Mercado Livre de Energia. Isso significa que atendemos especialmente clientes que já estão ou podem migrar para este modelo de contratação da energia elétrica. De acordo com a regulamentação brasileira do Ambiente de Contratação Livre (ACL), apenas podem comprar energia direto do fornecedor os consumidores do Grupo A, aqueles que recebem energia de fios de média e alta tensão. "
        }
      },
      {
        "@type": "Question",
        "name": "Como posso contratar os serviços da Clarke Energia?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Você pode contratar os serviços da Clarke Energia entrando em contato com um consultor especialista e fazendo uma simulação de economia detalhada através dos dados existentes na conta de luz da sua empresa;"
        }
      },
      {
        "@type": "Question",
        "name": "Como a Clarke pode me ajudar se eu já estou no Mercado Livre de Energia?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A Clarke Energia é uma gestora independente. Desta forma, além de realizar a migração de formato de contratação para quem ainda não está no Mercado Livre, atendemos clientes que já fazem parte do ACL entregando melhores resultados de eficiência energética, através do acompanhamento personalizado e da disponibilização de relatórios de consumo, economia e sustentabilidade. Além disso, nossos gestores estão sempre ligados no preço da energia, como forma de o cliente Clarke sempre ter o melhor direcionamento sobre quando e quanto comprar de energia.  Para quem é cliente atacadista, a Clarke também atua comprando e vendendo energia no Mercado de Curto Prazo, quando houver a necessidade baseada no acompanhamento do consumo do cliente."
        }
      },
      {
        "@type": "Question",
        "name": "Como a Clarke pode me ajudar se eu já possuo placas solares?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Caso sua empresa possua placas solares e faça parte do sistema de Geração Distribuída de Energia, a Clarke poderá te ajudar em caso de gasto excedente à energia gerada pelos seus painéis. Nossos consultores especialistas irão analisar qual o volume de energia está sendo gerado x consumido e como o Mercado Livre de Energia pode te ajudar ou não, a reduzir possíveis custos. Também podemos ajudar caso o plano seja expandir sua empresa, e portanto ultrapassar os gastos atuais de energia."
        }
      }
    ]
  };