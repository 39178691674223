import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import styles from './style.module.css';

interface HistoryCardProps {
  item: {
    '@type': string;
    name: string;
    image: {
      '@type': string;
      url: string;
      width: number;
      height: number;
      caption: string;
    };
    description?: JSX.Element | string;
  };
  index: number;
  historyItemsLength: number;
}

const HistoryCard: React.FC<HistoryCardProps> = ({ item, index, historyItemsLength }) => {
  const isSmallScreen = useScreenSize();
  const cardClasses = twMerge(isSmallScreen ? 'flex-row-reverse' : 'flex-col w-[15.25rem]', styles.historyCard);
  const titleCardClasses = twMerge(
    isSmallScreen ? 'ml-1 text-heading-small' : 'ml-0 text-heading-medium',
    styles.cardTitle,
  );

  return (
    <div key={`historyGroup-${index}`} className={cardClasses}>
      <img
        src={item.image.url}
        alt={item.image.caption}
        className={styles.imageCard}
        loading="lazy"
        decoding="async"
      />
      {!isSmallScreen && (
        <div className={styles.desktopLineWrapper}>
          <div className={styles.desktopLine} role="presentation" aria-hidden="true" />
          <div className="h-1 bg-brand-primary-0 w-[95%]" role="presentation" aria-hidden="true" />
        </div>
      )}
      <div className="flex flex-col w-full">
        <h2 className={titleCardClasses}>{item.name}</h2>
        <div className="text-brand-gray-80 text-paragraph-small w-full">{item.description}</div>
      </div>
      {isSmallScreen && (
        <div>
          {index < historyItemsLength - 1 && (
            <>
              {index !== 0 && <div className={styles.mobileVerticalLine} role="presentation" aria-hidden="true" />}
              <div
                className={twMerge(styles.mobileDot, index === 0 ? 'mt-4' : '')}
                role="presentation"
                aria-hidden="true"
              />
              <div className={styles.mobileLargeVerticalLine} role="presentation" aria-hidden="true" />
            </>
          )}
          {index === historyItemsLength - 1 && (
            <>
              {index !== 0 && <div className={styles.mobileVerticalLine} role="presentation" aria-hidden="true" />}
              <div
                className={twMerge(styles.mobileDot, index === 0 ? 'mt-4' : '')}
                role="presentation"
                aria-hidden="true"
              />
              <div className="min-h-[8rem] w-1 bg-brand-primary-0 ml-4" role="presentation" aria-hidden="true" />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default HistoryCard;
