import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import ButtonComponent from '@components/atoms/button/button';

import lampLightBackground from '@assets/light.png';

import style from './style.module.css';

const ChallengeSection = () => {
  const isSmallScreen = useScreenSize();
  const challengeContainer = twMerge(isSmallScreen ? 'px-4 py-12' : 'px-20 pt-20', style.ChallengeContainer);
  const challengeContent = twMerge(
    isSmallScreen ? 'text-paragraph-small w-full' : 'text-paragraph-large min-w-[40.56rem]',
    style.ChallengeContent,
  );
  const challengeTitle = twMerge(
      isSmallScreen ? 'text-heading-medium leading-[2rem]' : 'mb-8 text-display-small leading-[4.5rem] w-[40.5625rem] ',
    'font-bold',
  );
  const challengeDescription = twMerge(
    isSmallScreen ? 'text-paragraph-large -mt-2' : 'text-heading-small w-[34.5625rem]',
    'font-regular',
  );
  const challengeBtn = twMerge(isSmallScreen ? 'w-full flex justify-between text-paragraph-large h-12' : 'w-fit text-center text-heading-xSmall h-14', style.ChallengeBtn);

  const targetRef = useRef(null);

  const handleClick = () => {
    if (targetRef.current) {
      const offsetTop = (targetRef.current as HTMLElement).getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 100,
        behavior: 'smooth',
      });
    }
  };

  return (
    <section className={style.challengeSection} aria-labelledby="market-challenge-heading">
      <div className={challengeContainer}>
        <div className={challengeContent}>
          <h2 id="market-challenge-heading" className={challengeTitle}>
            Os <span className="text-palette-orange-30">desafios</span> do Mercado de Energia no Brasil
          </h2>

          {isSmallScreen && (
            <img
              src={lampLightBackground}
              alt="Foto de duas lampadas, uma ao lado da outra"
              width={591}
              height={518}
              className="w-[36.9375rem] h-auto mx-auto "
              aria-hidden="true"
              loading="lazy"
              decoding="async"
            />
          )}

          <p className={challengeDescription}>
            <span className="font-bold text-palette-orange-30">
              Preços altos, burocracias, instabilidade regulatória e pouca liberdade para o consumidor, com poucos benefícios  {' '}
            </span>
            para as indústrias. Esses são apenas alguns dos desafios do mercado de energia no Brasil.
          </p>

          <p className={twMerge(challengeDescription, 'mt-6')}>
            Por isso o <span className="font-bold text-brand-primary-60"> Mercado Livre de Energia </span> é a melhor
            alternativa.
          </p>
          <ButtonComponent
            icon="ArrowDownIcon"
            iconPosition="right"
            iconType="solid"
            iconSize='h-[1.275rem]'
            iconStyles={twMerge(isSmallScreen ? 'w-8 h-8' : 'w-10 h-10', "bg-brand-light-20 text-brand-primary-70")}
            labelSize='w-full ml-2'
            kind="primaryCustom"
            size="2"
            label="Saiba mais"
            onClick={handleClick}
            className={challengeBtn}
          />
        </div>
        <div className={style.lampLightBackground}></div>
        {!isSmallScreen && (
          <img src={lampLightBackground} alt="Lampadas" className={style.ImageDesktop} aria-hidden="true" />
        )}
      </div>
      <div id="como-funciona" ref={targetRef} />
    </section>
  );
};

export default ChallengeSection;
