import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';
import { useModalContext } from '@contexts/modalContext';

import style from './style.module.css';
import FormComponent from '@components/atoms/form';

import calculatorImg from '@assets/calculator.png';

const EconomySimulator: React.FC = () => {
  const isSmallScreen = useScreenSize();
  const { isModalOpen } = useModalContext();

  const containerClasses = twMerge(
    style.EconomySimulatorContainer,
    isSmallScreen ? 'flex-col px-4 py-8' : 'flex-row pt-16 px-20 pb-20',
  );
  const imageContainerClasses = twMerge(style.EconomySimulatorImageContainer, isSmallScreen ? 'hidden' : 'flex');
  const headingClasses = twMerge(
    style.EconomySimulatorHeading,
    isSmallScreen ? 'text-heading-medium leading-[2.5rem] w-full' : 'text-heading-xxLarge leading-[3rem] w-[43.5rem]',
  );
  const imageClasses = twMerge(style.EconomySimulatorImage, isSmallScreen ? 'flex' : 'hidden');
  const contentClasses = twMerge(style.EconomySimulatorContent, isSmallScreen ? 'mt-0' : 'mt-8 justify-end items-end');
  const formClasses = twMerge(style.EconomySimulatorForm, isSmallScreen ? 'py-8 px-4' : 'p-8 w-[39.4375rem]');

  const transitionClass = 'transition-opacity duration-300';

  return (
    <section id="simulador-de-energia" className={style.EconomySimulatorSection}>
      <div className={containerClasses}>
        <div className={imageContainerClasses}>
          <img src={calculatorImg} alt="Foto de duas calculadoras com o fundo verde" />
        </div>
        <div className={contentClasses}>
          <h2 className={headingClasses}>
            Faça a simulação e veja o <span className="text-brand-primary-60">potencial de economia</span> da sua
            empresa
          </h2>
          <div className={imageClasses}>
            <img
              src={calculatorImg}
              alt="Foto de duas calculadoras com o fundo verde"
              className="w-[11.25rem] h-[11.25rem]"
              loading="lazy"
              decoding="async"
            />
          </div>
          {!isModalOpen ? (
            <div
              className={twMerge(
                `style.style.EconomyForm ${transitionClass} ${isModalOpen ? 'opacity-0' : 'opacity-100'} ${isSmallScreen && 'mx-auto w-full'}`,
              )}
            >
              <FormComponent formStyle={formClasses} />
            </div>
          ) : (
            <div
              className={twMerge(
                `style.EconomyForm ${transitionClass} ${isModalOpen ? 'opacity-100' : 'opacity-0'} ${isSmallScreen && 'mx-auto'}`,
              )}
            >
              <p className="h-full flex items-center text-center">
                O formulário já está aberto em outra parte da página. Por favor, feche-o para visualizá-lo aqui!
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default EconomySimulator;
