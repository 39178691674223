import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroIcon, { IconName, IconType } from '@components/atoms/hero-icon';

import style from './style.module.css';

export interface ClarkeServiceCardProps {
  iconName: IconName;
  iconType: IconType;
  title: string;
  description: string;
  highlightCardTitleColor: string;
}

const ClarkeServiceCard: React.FC<ClarkeServiceCardProps> = ({
  iconName,
  iconType,
  title,
  description,
  highlightCardTitleColor,
}) => {
  const isSmallScreen = useScreenSize();
  const Card = twMerge(isSmallScreen ? 'w-full' : 'w-1/3', style.Card);
  const CardTitle = twMerge(isSmallScreen ? 'text-heading-medium' : 'text-heading-xLarge', style.CardTitle, style.highlightCardTitleColor);
  const CardDescription = twMerge(
    isSmallScreen ? 'text-paragraph-small' : 'text-paragraph-medium',
    style.CardDescription,
  );

  return (
    <div className={Card}>
      <div className={twMerge(highlightCardTitleColor, style.CardIcon)}>
        <div className="h-10 w-10">
        <HeroIcon type={iconType} icon={iconName} />
        </div>
      </div>
      <h3 className={twMerge(highlightCardTitleColor, CardTitle)}>{title}</h3>
      <p className={CardDescription}>{description}</p>
    </div>
  );
};

export default ClarkeServiceCard;
