import { BrowserRouter as Router } from 'react-router-dom';
import InstitutionalSiteRouter from '@routers/institutional-site-router';

import { ModalProvider } from '@contexts/modalContext';

function App() {
  return (
    <Router>
      <ModalProvider>
        <InstitutionalSiteRouter />
      </ModalProvider>
    </Router>
  );
}

export default App;
