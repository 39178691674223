import React from 'react';

import TabsContainer from '@components/organisms/tabs-container/tabs-container';

const ProductsCardsSection: React.FC = () => (
  <div>
    <TabsContainer />
  </div>
);

export default ProductsCardsSection;
